import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
import Modal from 'react-modal';
import history from 'technical/history';
import MenuLink from 'ui/menu/link';

import { NavSection } from 'ui/navBar/components/fullNavBar';
import colors from 'config/colors';
import styles from './index.module.scss';

interface Props {
  modalIsOpen: boolean;
  closeModal: () => void;
  navSections: NavSection[];
}

function MenuModal({ modalIsOpen, closeModal, navSections }: Props) {
  const activeSectionPath: string = history.location.pathname;

  function pushPath(path: string) {
    return () => {
      history.push(path);
    };
  }

  return (
    <Modal className={styles.menuModal} isOpen={modalIsOpen}>
      <CloseOutlined
        onClick={closeModal}
        className={styles.closeIcon}
        style={{ color: colors.white }}
      />
      <nav className={styles.navLinks}>
        {navSections.map(section => (
          <MenuLink
            key={section.path}
            onClick={pushPath(section.path)}
            style={
              activeSectionPath === section.path
                ? { color: colors.primaryColor }
                : undefined
            }
            className={styles.menuLink}
          >
            {section.label}
          </MenuLink>
        ))}
      </nav>
    </Modal>
  );
}

export default MenuModal;
