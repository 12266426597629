import gql from 'graphql-tag';

const TrainingFields = gql`
  fragment TrainingFields on training {
    id
    name
    dateStart
    dateEnd
    address
    postalCode
    city
    nbSeatsAvailable
    nbSeats
    region {
      user {
        email
        firstName
        lastName
        phone
      }
      name
    }
    user_trainings {
      invalidationDate
      validationDate
    }
  }
`;

export const GET_MY_TRAININGS = gql`
  ${TrainingFields}
  query($userId: uuid!) {
    nextTraining: training(
      where: {
        user_trainings: {
          _and: {
            user_id: { _eq: $userId }
            _and: [
              { validationDate: { _is_null: true } }
              { invalidationDate: { _is_null: true } }
            ]
          }
        }
      }
      order_by: { dateStart: asc }
    ) {
      ...TrainingFields
    }
    lastTraining: training(
      where: {
        user_trainings: {
          _and: {
            user_id: { _eq: $userId }
            _or: [
              { validationDate: { _is_null: false } }
              { invalidationDate: { _is_null: false } }
            ]
          }
        }
      }
      order_by: { dateStart: desc }
    ) {
      ...TrainingFields
    }
  }
`;

export const GET_NEW_TRAININGS = gql`
  ${TrainingFields}
  query(
    $type: [String!]
    $now: timestamptz!
    $userId: uuid!
    $regionId: uuid!
  ) {
    training(
      where: {
        type: { _in: $type }
        dateEnd: { _gt: $now }
        nbSeatsAvailable: { _gt: 0 }
        regionId: { _eq: $regionId}
        _not: { user_trainings: { user_id: { _eq: $userId } } }
      }
    ) {
      ...TrainingFields
    }
  }
`;

export const REGISTER_TO_TRAINING = gql`
  mutation($trainingId: uuid!, $userId: uuid!, $nbSeatsAvailable: Int!) {
    insert_user_training(
      objects: { training_id: $trainingId, user_id: $userId }
    ) {
      affected_rows
    }
    update_training(
      where: { id: { _eq: $trainingId } }
      _set: { nbSeatsAvailable: $nbSeatsAvailable }
    ) {
      affected_rows
    }
  }
`;

export const SEND_REGISTER_EMAIL = gql`
  mutation(
    $trainingId: String!
    $userEmail: String!
    $trainingName: String!
    $trainingStart: String!
    $trainingAddress: String
    $trainingPostalCode: String
    $trainingCity: String
    $regionManager: String!
    $trainingEnd: String!
    $regionName: String!
  ) {
    trainingRegisterEmail(
      input: {
        trainingId: $trainingId
        userEmail: $userEmail
        trainingName: $trainingName
        trainingStart: $trainingStart
        trainingAddress: $trainingAddress
        trainingPostalCode: $trainingPostalCode
        trainingCity: $trainingCity
        regionManager: $regionManager
        trainingEnd: $trainingEnd
        regionName: $regionName
      }
    ) {
      success
    }
  }
`;

export const SEND_UNREGISTER_EMAIL = gql`
  mutation(
    $trainingId: String!
    $userEmail: String!
    $trainingName: String!
    $trainingStart: String!
    $trainingAddress: String
    $trainingPostalCode: String
    $trainingCity: String
    $regionManager: String!
    $trainingEnd: String!
    $regionName: String!
  ) {
    trainingUnregisterEmail(
      input: {
        trainingId: $trainingId
        userEmail: $userEmail
        trainingName: $trainingName
        trainingStart: $trainingStart
        trainingAddress: $trainingAddress
        trainingPostalCode: $trainingPostalCode
        trainingCity: $trainingCity
        regionManager: $regionManager
        trainingEnd: $trainingEnd
        regionName: $regionName
      }
    ) {
      success
    }
  }
`;

export const CREATE_INDIVIDUAL_TRAINING = gql`
  ${TrainingFields}
  mutation($regionId: uuid!, $type: String!) {
    insert_training(
      objects: {
        name: "Formation individuelle"
        regionId: $regionId
        type: $type
      }
    ) {
      returning {
        ...TrainingFields
      }
    }
  }
`;

export const UNREGISTER_FROM_COLLECTIVE_TRAINING = gql`
  mutation($userId: uuid!, $trainingId: uuid!, $nbSeatsAvailable: Int!) {
    delete_user_training(
      where: {
        _and: [
          { training_id: { _eq: $trainingId } }
          { user_id: { _eq: $userId } }
        ]
      }
    ) {
      affected_rows
    }

    update_training(
      where: { id: { _eq: $trainingId } }
      _set: { nbSeatsAvailable: $nbSeatsAvailable }
    ) {
      affected_rows
    }
  }
`;

export const UNREGISTER_FROM_INDIVIDUAL_TRAINING = gql`
  mutation($userId: uuid!, $trainingId: uuid!) {
    delete_user_training(
      where: {
        _and: [
          { training_id: { _eq: $trainingId } }
          { user_id: { _eq: $userId } }
        ]
      }
    ) {
      affected_rows
    }

    delete_training(where: { id: { _eq: $trainingId } }) {
      affected_rows
    }
  }
`;

export type Training = {
  id: string;
  name: string;
  dateStart: string;
  dateEnd: string;
  address: string;
  postalCode: string;
  city: string;
  nbSeatsAvailable: number;
  nbSeats: number | null;
  region: {
    user: {
      email: string | null;
      firstName: string | null;
      lastName: string | null;
      phone: string | null;
    };
    name: string;
  };
  regionId: string;
  user_trainings: {
    validationDate: string | null;
    invalidationDate: string | null;
  }[];
};

export type CreateTraniningData = {
  insert_training: {
    returning: Training[];
  };
};

export type NewTrainingData = {
  training: Training[];
};

export type MyTrainingData = {
  nextTraining: Training[];
  lastTraining: Training[];
};
