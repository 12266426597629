/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */

interface IErrorReporting {
  error(error: Error, errorInfo?: {}): void;
  info(error: Error, errorInfo?: {}): void;
  warning(error: Error, errorInfo?: {}): void;
  setUser(user: { id: string; username?: string; email?: string }): void;
  removeUser(): void;
}

/**
 * Mocks class
 */
class ErrorReportingMocks implements IErrorReporting {
  setUser() {}

  error(error: Error, errorInfo?: {}) {
    console.error(error, errorInfo);
  }

  info(error: Error, errorInfo?: {}) {
    console.info(error, errorInfo);
  }

  warning(error: Error, errorInfo?: {}) {
    console.warn(error, errorInfo);
  }

  removeUser() {}
}

const errorReporting: IErrorReporting = new ErrorReportingMocks();

export default errorReporting;
