import { Result } from 'antd';
import { useAppContext } from 'business/provider';
import ConnectedPage from 'business/layout/connectedPage';
import DisconnectedPage from 'business/layout/disconnectedPage';
import React, { useEffect } from 'react';
import { Route } from 'react-router';
import history from 'technical/history';
import Button from 'ui/button';
import errorReporting from 'technical/error-reporting';

function ReportingNoMatch() {
  const { isConnected } = useAppContext();
  useEffect(() => {
    errorReporting.info(new Error('404'), { location: history.location });
  }, []);

  const Page = isConnected ? ConnectedPage : DisconnectedPage;

  return (
    <Route
      component={() => (
        <Page>
          <Result
            status="404"
            title="404"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
              <Button type="primary" onClick={() => history.goBack()}>
                Back
              </Button>
            }
          />
        </Page>
      )}
    />
  );
}

export default ReportingNoMatch;
