import gql from 'graphql-tag';

export default gql`
  mutation UpdateUser(
    $id: uuid
    $firstname: String
    $lastname: String
    $civility: String
    $phone: String
    $regionId: uuid
    $origin: String
    $siteId: uuid
    $newPackNotification: Boolean
    $inscriptionNotification: Boolean
  ) {
    update_user(
      where: { id: { _eq: $id } }
      _set: {
        firstName: $firstname
        lastName: $lastname
        civility: $civility
        phone: $phone
        regionId: $regionId
        origin: $origin
        siteId: $siteId
        newPackNotification: $newPackNotification
        inscriptionNotification: $inscriptionNotification
      }
    ) {
      affected_rows
    }
    insert_user_region_animation(
      objects: { region_id: $regionId, user_id: $id },
      on_conflict: {
        constraint: user_region_animation_pkey
        update_columns: []
      }
    ) {
      affected_rows
    }
  }
`;
