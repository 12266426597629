import gql from 'graphql-tag';

export const GET_MY_EVALUATIONS = gql`
  query($userId: uuid!) {
    speakerEvaluation: speakerEvaluation2425(where: { intervenant_id: { _eq: $userId } }) {
      id
      episode_id
    }
  }
`;

export const GET_EPISODE = gql`
  query($episodeId: uuid!) {
    episode_by_pk(id: $episodeId) {
      id
      firstAnimateurId
      secondAnimateurId
      expertId
      number
      class {
        id
        level
        episodes {
          number
          id
          firstAnimateurId
          secondAnimateurId
        }
      }
    }
  }
`;

export const GET_MY_EVALUATION = gql`
  query($userId: uuid!, $evaluationId: uuid!) {
    speakerEvaluation: speakerEvaluation2425(
      where: {
        _and: [
          { intervenant_id: { _eq: $userId } }
          { id: { _eq: $evaluationId } }
        ]
      }
    ) {
      pathChosen
      enoughTime
      startOnTime
      sameTeacher
      helpFacilitateSession
      studentAttention
      studentInvolvement
      resolutionOrChallenge
      resolutionOrChallengeHolding
      computer
      videoProjector
      technicalIssueEJ
      technicalIssueRoom
      overallImpression
      comments
    }
  }
`;

export const ADD_SPEAKER_EVALUATION = gql`
  mutation(
    $pathChosen: String
    $enoughTime: String!
    $startOnTime: String!
    $sameTeacher: String!
    $helpFacilitateSession: String!
    $studentAttention: String!
    $studentInvolvement: String!
    $resolutionOrChallenge: String!
    $resolutionOrChallengeHolding: String!
    $computer: String!
    $videoProjector: String!
    $technicalIssueEJ: String
    $technicalIssueRoom: String
    $overallImpression: String!
    $comments: String!
    $episodeId: uuid!
    $userId: uuid!
  ) {
    insert_speakerEvaluation2425(
      objects: {
        pathChosen: $pathChosen
        enoughTime: $enoughTime
        startOnTime: $startOnTime
        sameTeacher: $sameTeacher
        helpFacilitateSession: $helpFacilitateSession
        studentAttention: $studentAttention
        studentInvolvement: $studentInvolvement
        resolutionOrChallenge: $resolutionOrChallenge
        resolutionOrChallengeHolding: $resolutionOrChallengeHolding
        computer: $computer
        videoProjector: $videoProjector
        technicalIssueEJ: $technicalIssueEJ
        technicalIssueRoom: $technicalIssueRoom
        overallImpression: $overallImpression
        comments: $comments
        episode_id: $episodeId
        intervenant_id: $userId
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_SPEAKER_EVALUATION = gql`
  mutation(
    $pathChosen: String
    $enoughTime: String!
    $startOnTime: String!
    $sameTeacher: String!
    $helpFacilitateSession: String!
    $studentAttention: String!
    $studentInvolvement: String!
    $resolutionOrChallenge: String!
    $resolutionOrChallengeHolding: String!
    $computer: String!
    $videoProjector: String!
    $technicalIssueEJ: String
    $technicalIssueRoom: String
    $overallImpression: String!
    $comments: String!
    $evaluationId: uuid!
  ) {
    update_speakerEvaluation2425(
      where: { id: { _eq: $evaluationId } }
      _set: {
        pathChosen: $pathChosen
        enoughTime: $enoughTime
        startOnTime: $startOnTime
        sameTeacher: $sameTeacher
        helpFacilitateSession: $helpFacilitateSession
        studentAttention: $studentAttention
        studentInvolvement: $studentInvolvement
        resolutionOrChallenge: $resolutionOrChallenge
        resolutionOrChallengeHolding: $resolutionOrChallengeHolding
        computer: $computer
        videoProjector: $videoProjector
        technicalIssueEJ: $technicalIssueEJ
        technicalIssueRoom: $technicalIssueRoom
        overallImpression: $overallImpression
        comments: $comments
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_CLASS_THEME_FROM_EVAL = gql`
  mutation($id: uuid!) {
    classUpdateThemeFromEvaluation(input: { id: $id }) {
      success
    }
  }
`;

export const UPDATE_CLASS_THEME = gql`
  mutation($theme: String, $classId: uuid) {
    update_class(where: { id: { _eq: $classId } }, _set: { theme: $theme }) {
      affected_rows
    }
  }
`;

export const GET_EXPERT_EVAL_PATH_CHOSEN = gql`
  query($episodeId: uuid, $expertId: uuid) {
    speakerEvaluation: speakerEvaluation2425(
      where: {
        episode_id: { _eq: $episodeId }
        intervenant_id: { _eq: $expertId }
      }
    ) {
      pathChosen
    }
  }
`;

export const GET_ANIMATEUR_EVAL_PATH_CHOSEN = gql`
  query($episodeId: uuid, $firstAnimateurId: uuid) {
    speakerEvaluation: speakerEvaluation2425(
      where: {
        episode_id: { _eq: $episodeId }
        intervenant_id: { _eq: $firstAnimateurId }
      }
    ) {
      pathChosen
    }
  }
`;

export const GET_ALL_INFOS_FOR_PATH_DECISIONS = gql`
  query($classId: uuid) {
    speakerEvaluation: speakerEvaluation2425(
      where: { episode: { classId: { _eq: $classId } } }
      order_by: { episode: { number: asc } }
    ) {
      pathChosen
      episode {
        number
      }
      intervenant_id
    }
    class(where: { id: { _eq: $classId } }) {
      episodes(order_by: { number: asc }) {
        number
        firstAnimateurId
        secondAnimateurId
        expertId
      }
    }
  }
`;
  