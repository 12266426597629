import { Typography } from 'antd';
import React from 'react';
import ConnectedPage from 'business/layout/connectedPage';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import Title from '../../../ui/title';

function NotPermittedUser({ title, text }: { title: string; text: string }) {
  const { t } = useTranslation();

  return (
    <ConnectedPage>
      <div className={styles.wrapper}>
        <Title title={t(title)} />
        <Typography.Text>{t(text)}</Typography.Text>
      </div>
    </ConnectedPage>
  );
}

export default NotPermittedUser;
