import { ColumnFilterItem, ColumnType } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import Capitalize from 'technical/capitalize';
import { useMemoryColumn } from '../TabContext';
import sortFunction from '../../../technical/sort';
import { importExportTypeClasses } from '../api';
import getTime from '../../../technical/dateFormatter/dateFormatter';
import { ColumnProps, Row } from '../types/EpisodeTableTypes';

function makeFilter(value: string): ColumnFilterItem {
  return {
    text: value,
    value,
  };
}

function useEstablishmentColumn(props: ColumnProps): ColumnType<Row> {
  const { t } = useTranslation();

  const filters = useMemo(() => {
    return props.packsData
      ? Array.from(
          new Set(props.packsData.class.map(pack => pack.establishment.name)),
        ).map(makeFilter)
      : [];
  }, [props.packsData]);

  return {
    ...useMemoryColumn(props.idTable, 'educational-establishment'),
    title: t('episode.my.table.educational-establishment'),
    render: (_, row) => row.establishment.name,
    sorter: (a, b) => sortFunction(a.establishment.name, b.establishment.name),
    filters,
    onFilter: (value, record) => record.establishment.name === value,
  };
}

function useRegionColumn(props: ColumnProps): ColumnType<Row> {
  const { t } = useTranslation();

  const filters = useMemo(() => {
    return props.packsData
      ? Array.from(
          new Set(
            props.packsData.class.map(pack => pack.establishment.region.name),
          ),
        ).map(makeFilter)
      : [];
  }, [props.packsData]);

  return {
    ...useMemoryColumn(props.idTable, 'region'),
    title: t('episode.my.table.region'),
    render: (_, row) => row.establishment.region.name,
    sorter: (a, b) =>
      sortFunction(a.establishment.region.name, b.establishment.region.name),
    filters,
    onFilter: (value, record) => record.establishment.region.name === value,
  };
}
function useEstablishmentAddressColumn(props: ColumnProps): ColumnType<Row> {
  const { t } = useTranslation();
  return {
    ...useMemoryColumn(props.idTable, 'address'),
    title: t('episode.my.table.address'),
    render: (_, row) =>
      (row.establishment.addressSecondary &&
      row.establishment.addressSecondary.trim()
        ? row.establishment.addressSecondary
        : `${row.establishment.address},\n${row.establishment.postalCode} ${row.establishment.city}`) +
      (row.episode.classroomNumber && row.episode.teacher
        ? `\n${t('common.room')} ${row.episode.classroomNumber} ${t(
            'common.with',
          )} ${Capitalize(
            row.episode.teacher.civility,
          )} ${row.episode.teacher.lastName.toUpperCase()} ${Capitalize(
            row.episode.teacher.firstName,
          )}`
        : ''),
    sorter: (a, b) =>
      sortFunction(
        a.establishment?.addressSecondary &&
          a.establishment.addressSecondary.trim()
          ? a.establishment?.addressSecondary
          : a.establishment.address,
        b.establishment?.addressSecondary &&
          b.establishment.addressSecondary.trim()
          ? b.establishment?.addressSecondary
          : b.establishment.address,
      ),
  };
}

function useClassNameColumn(props: ColumnProps): ColumnType<Row> {
  const { t } = useTranslation();

  const filters = useMemo(() => {
    return props.packsData
      ? Array.from(new Set(props.packsData.class.map(pack => pack.name))).map(
          makeFilter,
        )
      : [];
  }, [props.packsData]);

  return {
    ...useMemoryColumn(props.idTable, 'classroom'),
    title: t('episode.my.table.classroom'),
    dataIndex: 'name',
    sorter: (a, b) => sortFunction(a.name, b.name),
    filters,
    onFilter: (value, record) => record.name === value,
  };
}

function useLevelColumn(props: ColumnProps): ColumnType<Row> {
  const { t } = useTranslation();

  const filters = useMemo(() => {
    return props.packsData
      ? Array.from(new Set(props.packsData.class.map(pack => pack.level))).map(
          text => ({
            text: t(`episode.table.levelChoices.${text}`),
            value: text,
          }),
        )
      : [];
  }, [props.packsData]);

  return {
    ...useMemoryColumn(props.idTable, 'level'),
    title: t('episode.table.level'),
    render: (_, row) => t(`episode.table.levelChoices.${row.level}`),
    sorter: (a, b) => sortFunction(a.level, b.level),
    filters,
    onFilter: (value, record) => record.level === value,
  };
}

function useTypeColumn(props: ColumnProps): ColumnType<Row> {
  const { t } = useTranslation();
  return {
    ...useMemoryColumn(props.idTable, 'type'),
    title: t('episode.table.type'),
    render: (_, row) => t(`episode.table.typeChoices.${row.type}`),
    sorter: (a, b) => sortFunction(a.type, b.type),
    filters: importExportTypeClasses.map(value => ({
      text: t(`episode.table.typeChoices.${value}`),
      value,
    })),
    onFilter: (value, record) => record.type === value,
  };
}

function useEpisodeColumn(props: ColumnProps): ColumnType<Row> {
  const { t } = useTranslation();
  return {
    ...useMemoryColumn(props.idTable, 'episode'),
    title: t('episode.my.table.episode'),
    render: (_, row) => row.episode.number,
    sorter: (a, b) => sortFunction(a.episode.number, b.episode.number),
    filters: [1, 2, 3].map(value => ({
      text: value,
      value,
    })),
    onFilter: (value, record) => record.episode.number === value,
  };
}

function useDateColumn(props: ColumnProps): ColumnType<Row> {
  const { t } = useTranslation();
  return {
    ...useMemoryColumn(props.idTable, 'date'),
    title: t('episode.my.table.date'),
    render: (_, row) => {
      return row.episode.dateStart && row.episode.dateEnd
        ? Intl.DateTimeFormat('fr-FR', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          }).format(new Date(row.episode.dateStart))
        : t('episode.my.table.not-planned');
    },
    sorter: (a, b) =>
      sortFunction(
        new Date(a.episode.dateStart),
        new Date(b.episode.dateStart),
      ),
  };
}

function useHourColumn(props: ColumnProps): ColumnType<Row> {
  const { t } = useTranslation();
  return {
    ...useMemoryColumn(props.idTable, 'hour'),
    title: t('episode.my.table.hour'),
    render: (_, row) => {
      if (row.episode.dateStart && row.episode.dateEnd) {
        const dateStart = new Date(row.episode.dateStart);
        const dateEnd = new Date(row.episode.dateEnd);

        return `${getTime(dateStart)}-${getTime(dateEnd)}`;
      }
      return '-';
    },
    sorter: (a, b) =>
      sortFunction(
        getTime(new Date(a.episode.dateStart)),
        getTime(new Date(b.episode.dateEnd)),
      ),
  };
}

export {
  useEstablishmentColumn,
  useEstablishmentAddressColumn,
  useClassNameColumn,
  useLevelColumn,
  useTypeColumn,
  useEpisodeColumn,
  useDateColumn,
  useHourColumn, useRegionColumn,
};
