import { UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu } from 'antd';
import { useAppContext } from 'business/provider';
import { login, logout } from 'business/user/services/authentication';
import classnames from 'classnames';
import colors from 'config/colors';
import layout from 'config/layout';
import React from 'react';
import history from 'technical/history';
import AppLogo from 'ui/appLogo';
import MenuLink from 'ui/menu/link';
import Section from 'ui/navBar/components/logoSection';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

const { Header } = Layout;

export interface NavSection {
  label: string;
  path: string;
}

interface Props {
  isConnected?: boolean;
}

export default function FullNavBar({ isConnected }: Props) {
  const { user } = useAppContext();
  const { t } = useTranslation();

  const activeSectionPath = history.location.pathname;

  function handleLoginOnClick() {
    login();
  }
  const headerClasses = [styles.header, 'elevation2'];

  if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    if (process.env.REACT_APP_ENVIRONMENT === 'test') {
      headerClasses.push(styles.recette);
    } else {
      headerClasses.push(styles.notProd);
    }
  }
  return (
    <Header className={classnames(...headerClasses)}>
      <div className={styles.headerContent}>
        <div className={styles.headerLeft}>
          <AppLogo />

          <nav className={styles.navLinks}>
            {user?.isRegistered &&
              user?.isActive &&
              layout.header.menuSections
                .filter(menu => menu.allowedRoles.includes(user.type))
                .map(sectionItem => (
                  <MenuLink
                    key={sectionItem.label}
                    style={
                      activeSectionPath === sectionItem.path
                        ? { color: colors.primaryColor }
                        : undefined
                    }
                    path={sectionItem.path}
                  >
                    {sectionItem.label}
                  </MenuLink>
                ))}
          </nav>
        </div>

        {isConnected ? (
          <Dropdown
            className={styles.headerRight}
            overlay={
              <Menu>
                {user?.isActive ? (
                  <Menu.Item>
                    <MenuLink path="/my-profile">
                      {t('navbar.profile')}
                    </MenuLink>
                  </Menu.Item>
                ) : (
                  <></>
                )}
                <Menu.Item onClick={logout}>
                  <MenuLink>{t('navbar.disconnect')}</MenuLink>
                </Menu.Item>
              </Menu>
            }
          >
            <div className="ant-dropdown-link">
              <Section
                onClick={() => {}}
                icon={<UserOutlined style={{ fontSize: '36px' }} />}
                label={
                  user && user.lastName && user.firstName
                    ? `${user.firstName} ${user.lastName}`
                    : ''
                }
              />
            </div>
          </Dropdown>
        ) : (
          <div className={styles.headerRight}>
            <Button onClick={handleLoginOnClick}>Se connecter</Button>
          </div>
        )}
      </div>
    </Header>
  );
}
