import { Pack, Episode } from '../../api';

export function areEpisodeOverlapping(
  episode1: Episode | null,
  episode2: Episode | null,
  userType: string,
  isSameEstablisment: boolean,
) {
  // No overlap if one episode is null
  if (!episode1 || !episode2) {
    return false;
  }

  if (
    episode1[userType === 'animator' ? 'animator' : 'expert'] !== null &&
    episode2[userType === 'animator' ? 'animator' : 'expert'] !== null &&
    episode1[userType === 'animator' ? 'animator' : 'expert'] !==
      episode2[userType === 'animator' ? 'animator' : 'expert']
  ) {
    return false;
  }

  const dates1 = {
    start: new Date(episode1.dateStart),
    end: new Date(episode1.dateEnd),
  };
  const dates2 = {
    start: new Date(episode2.dateStart),
    end: new Date(episode2.dateEnd),
  };

  // No overlap if episodes are not on the same day
  const isSameDay =
    dates1.end.getDate() === dates2.end.getDate() &&
    dates1.end.getMonth() === dates2.end.getMonth() &&
    dates1.end.getFullYear() === dates2.end.getFullYear();
  if (!isSameDay) {
    return false;
  }

  if (isSameEstablisment) {
    // Episodes overlap if their date ranges overlap. See https://stackoverflow.com/a/325964
    return dates1.start < dates2.end && dates1.end > dates2.start;
  }

  // Episodes overlap if they are both before midday, or both after midday
  const hours1 = dates1.end.getHours();
  const hours2 = dates2.end.getHours();
  return (hours1 < 12 && hours2 < 12) || (hours1 > 12 && hours2 > 12);
}

export function arePacksOverlapping(
  pack1: Pack,
  pack2: Pack,
  userType: string,
): boolean {
  // No overlap if packs are the same
  if (pack1.key === pack2.key) {
    return false;
  }

  const isSameEstablisment = pack1.establishment.id === pack2.establishment.id;

  let isOverlapping = false;
  pack1.episodes.forEach(episodeFromPack1 => {
    pack2.episodes.forEach(episodeFromPack2 => {
      if (
        areEpisodeOverlapping(
          episodeFromPack1,
          episodeFromPack2,
          userType,
          isSameEstablisment,
        )
      ) {
        isOverlapping = true;
      }
    });
  });

  return isOverlapping;
}
