import React, { useEffect, useState } from 'react';
import ConnectedPage from 'business/layout/connectedPage';
import { Typography } from 'antd';
import Spacer from 'ui/spacer';
import Title from 'ui/title';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useAppContext } from 'business/provider';
import { useLazyQuery, useMutation } from '@apollo/client';
import history from 'technical/history';
import {
  ADD_SPEAKER_EVALUATION,
  GET_ALL_INFOS_FOR_PATH_DECISIONS,
  GET_EPISODE,
  GET_MY_EVALUATION,
  UPDATE_CLASS_THEME,
  UPDATE_SPEAKER_EVALUATION,
} from './api';
import { infoEpisodeApi } from './api/type';
import SpeakerEvaluationForm from './SpeakerEvaluationForm';
import {
  getRoleOnEpisode,
  mapEvalPaths,
  shouldUpdateClass,
} from './utils/utils';

export default function SpeakerEvaluation() {
  const { t } = useTranslation();
  const { user } = useAppContext();
  const [
    lazyMyEvaluation,
    { called, loading, data },
  ] = useLazyQuery(GET_MY_EVALUATION, { fetchPolicy: 'network-only' });
  const [addSpeakerEvaluation] = useMutation(ADD_SPEAKER_EVALUATION);
  const [updateSpeakerEvaluation] = useMutation(UPDATE_SPEAKER_EVALUATION);
  const [updateThemeClass] = useMutation(UPDATE_CLASS_THEME);

  const searchParams = new URLSearchParams(useLocation().search);
  const episodeId = searchParams.get('episode_id');
  const evaluationId = searchParams.get('evaluation_id');
  const [lazyEpisode, { data: dataGetEpisode }] = useLazyQuery(GET_EPISODE, {
    fetchPolicy: 'network-only',
  });

  const [path, setPath] = useState<string | undefined>(undefined);
  const [lazyInfoPathDecision, { data: infoForPathDecision }] = useLazyQuery<
    infoEpisodeApi
  >(GET_ALL_INFOS_FOR_PATH_DECISIONS, {
    fetchPolicy: 'network-only',
  });
  const episodeNumber = parseInt(dataGetEpisode?.episode_by_pk.number, 10);
  const classId = dataGetEpisode?.episode_by_pk?.class?.id;

  const pathMapped = infoForPathDecision
    ? mapEvalPaths(infoForPathDecision)
    : undefined;

  const role =
    user && infoForPathDecision?.class[0].episodes[episodeNumber - 1]
      ? getRoleOnEpisode(
          user,
          infoForPathDecision?.class[0].episodes[episodeNumber - 1],
        )
      : undefined;

  useEffect(() => {
    if (user && evaluationId) {
      lazyMyEvaluation({ variables: { userId: user.id, evaluationId } });
    }
    if (episodeId) {
      lazyEpisode({ variables: { episodeId } });
    }
  }, [user, evaluationId, lazyMyEvaluation, lazyEpisode, episodeId]);

  useEffect(() => {
    if (classId) {
      lazyInfoPathDecision({ variables: { classId } });
    }
  }, [classId, lazyInfoPathDecision]);

  useEffect(() => {
    if (
      pathMapped &&
      episodeNumber &&
      role &&
      classId &&
      path &&
      shouldUpdateClass(pathMapped, episodeNumber, role)
    ) {
      updateThemeClass({ variables: { classId, theme: path } });
    }
  }, [pathMapped, episodeNumber, role, classId, path, updateThemeClass]);
  const transformCheckboxValues = (arrayValue: string[] | undefined) => {
    const transformedValues = {
      technicalIssueRoom: '0',
      technicalIssueEJ: '0',
    };
    // Mettre à jour la valeur à '1' si l'option est sélectionnée
    arrayValue?.forEach((issue: string) => {
      transformedValues[issue as keyof typeof transformedValues] = '1';
    });
    return transformedValues;
  };
  return (
    <ConnectedPage>
      <div>
        <Title
          title={t('evaluation.title', {
            establishment: searchParams.get('establishment_name'),
            class: searchParams.get('class_name'),
            number: episodeNumber,
          })}
          withBackButton
        />
      </div>
      <Spacer />
      <Typography.Text style={{ padding: '0 12px' }}>
        {t('evaluation.text')}
      </Typography.Text>
      {evaluationId ? (
        called &&
        !loading && (
          <SpeakerEvaluationForm
            episodeNumber={episodeNumber}
            initialValues={{
              ...data.speakerEvaluation[0],
              technicalIssue: [
                data.speakerEvaluation[0].technicalIssueEJ === '1' &&
                  'technicalIssueEJ',
                data.speakerEvaluation[0].technicalIssueRoom === '1' &&
                  'technicalIssueRoom',
              ].filter(Boolean),
            }}
            onFinish={async values => {
              const { technicalIssue = [] } = values;
              const technicalIssueValues = transformCheckboxValues(
                technicalIssue,
              );
              const valueMutation = {
                ...values,
                ...technicalIssueValues,
                pathChosen: values.pathChosen || 'NC',
                evaluationId,
              };
              delete valueMutation.technicalIssue;
              await updateSpeakerEvaluation({
                variables: { ...valueMutation },
              });
              setPath(values.pathChosen);
              history.push('/episodes');
            }}
          />
        )
      ) : (
        <SpeakerEvaluationForm
          episodeNumber={episodeNumber}
          initialValues={{
            pathChosen: 'NC',
            enoughTime: undefined,
            startOnTime: undefined,
            sameTeacher: 'NC',
            helpFacilitateSession: undefined,
            studentAttention: undefined,
            studentInvolvement: undefined,
            resolutionOrChallenge: 'NC',
            resolutionOrChallengeHolding: 'NC',
            computer: undefined,
            videoProjector: undefined,
            technicalIssueEJ: undefined,
            technicalIssueRoom: undefined,
            overallImpression: undefined,
            comments: '',
          }}
          onFinish={async values => {
            const { technicalIssue = [] } = values;
            const technicalIssueValues = transformCheckboxValues(
              technicalIssue,
            );
            const valueMutation = {
              ...values,
              ...technicalIssueValues,
              pathChosen: values.pathChosen || 'NC',
              episodeId,
              userId: user?.id,
            };
            delete valueMutation.technicalIssue;
            await addSpeakerEvaluation({
              variables: { ...valueMutation },
            });
            setPath(values.pathChosen);
            history.push('/episodes');
          }}
        />
      )}
    </ConnectedPage>
  );
}
