import { ColumnType } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';
import { List, Typography } from 'antd';
import React from 'react';
import { useAppContext } from '../../provider';
import { useMemoryColumn } from '../TabContext';
import Button from '../../../ui/button';
import styles from './index.module.scss';
import sortFunction from '../../../technical/sort';
import { ColumnProps, Row } from '../types/EpisodeTableTypes';

export default function useAnimatorColumn(props: ColumnProps): ColumnType<Row> {
  const { t } = useTranslation();
  const { user } = useAppContext();

  return {
    ...useMemoryColumn(props.idTable, 'animator'),
    title: t('episode.my.table.animator'),
    render: (_, row) => {
      const dataSource = [row.episode.animator, row.episode.animator_2].filter(
        animator => {
          return animator !== null;
        },
      );
      function getAnimatorButton(animator: any) {
        return (
          <Button
            type="link"
            className={styles.buttonLink}
            onClick={() => {
              props.setModal({
                visible: true,
                title: `${animator?.firstName} ${animator?.lastName}`,
                content: (
                  <>
                    {animator?.email && (
                      <Typography.Paragraph>
                        {t('episode.my.modal.email', {
                          value: animator.email,
                        })}
                      </Typography.Paragraph>
                    )}
                    {animator?.phone && (
                      <Typography.Paragraph>
                        {t('episode.my.modal.phone', {
                          value: animator.phone,
                        })}
                      </Typography.Paragraph>
                    )}
                  </>
                ),
              });
            }}
          >
            {`${animator.firstName} ${animator.lastName}`}
          </Button>
        );
      }

      return dataSource.length > 0 ? (
        <List
          dataSource={dataSource}
          size="small"
          renderItem={animator => {
            if (animator) {
              if (animator?.id === user?.id) {
                return (
                  <List.Item>
                    {animator?.firstName} {animator?.lastName}
                  </List.Item>
                );
              }
              return <List.Item>{getAnimatorButton(animator)}</List.Item>;
            }
            return '';
          }}
        />
      ) : (
        '-'
      );
    },
    sorter: (a, b) =>
      sortFunction(
        a.episode?.animator?.lastName,
        b.episode?.animator?.lastName,
      ),
  };
}
