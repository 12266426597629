import React, { useEffect } from 'react';
import { Typography } from 'antd';
import history from 'technical/history';
import ConnectedPage from 'business/layout/connectedPage';
import Title from 'ui/title';
import Button from 'ui/button';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Flex from 'ui/flex';
import Spacer from 'ui/spacer';
import { useAppContext } from 'business/provider';
import {
  GET_MY_TRAININGS,
  MyTrainingData,
  UNREGISTER_FROM_COLLECTIVE_TRAINING,
  UNREGISTER_FROM_INDIVIDUAL_TRAINING,
  SEND_UNREGISTER_EMAIL,
} from './api';
import style from './index.module.scss';
import TrainingTable from './TrainingTable';

function EmptyFormation() {
  const { t } = useTranslation();
  return (
    <Flex column alignItems="center">
      <div className={style.text}>
        <Typography.Text>{t('new-training.my-trainings.text')}</Typography.Text>
      </div>

      <Button
        type="primary"
        onClick={() => history.push('/new-training-registration')}
      >
        {t('new-training.my-trainings.btn')}
      </Button>
    </Flex>
  );
}

function Trainings() {
  const { t } = useTranslation();
  const { user } = useAppContext();
  const [lazyMyTrainings, { called, loading, data }] = useLazyQuery<
    MyTrainingData
  >(GET_MY_TRAININGS, {
    fetchPolicy: 'network-only',
    variables: {
      userId: user?.id,
    },
  });
  const [unregisterFromCollectiveTraining] = useMutation(
    UNREGISTER_FROM_COLLECTIVE_TRAINING,
  );
  const [unregisterFromIndividualTraining] = useMutation(
    UNREGISTER_FROM_INDIVIDUAL_TRAINING,
  );

  const [sendUnregisterEmail] = useMutation(SEND_UNREGISTER_EMAIL);

  useEffect(() => {
    lazyMyTrainings();
  }, [lazyMyTrainings]);

  const neverEverHadAnyFormation =
    data?.nextTraining.length === 0 && data?.lastTraining.length === 0;

  if (neverEverHadAnyFormation) {
    return (
      <ConnectedPage>
        <div className={style.page}>
          <Title title={t('new-training.my-trainings.title')} />

          <Spacer />
          <EmptyFormation />
        </div>
      </ConnectedPage>
    );
  }

  return (
    <ConnectedPage>
      <div className={style.page}>
        <Title title={t('new-training.my-trainings.title')} />

        <Spacer />

        <Typography.Title level={3} className={style.subtitle}>
          {t('new-training.my-trainings.subtitle')}
        </Typography.Title>

        <span>
          <Typography.Text>
            {t('new-training.my-trainings.subtitleExplained.prefix')}
          </Typography.Text>
          <Typography.Text type="danger">
            {t('new-training.my-trainings.subtitleExplained.value')}
          </Typography.Text>
          <Typography.Text>
            {t('new-training.my-trainings.subtitleExplained.suffix')}
          </Typography.Text>
        </span>

        <Typography.Text />

        <Spacer />

        {data?.nextTraining.length === 0 ? (
          <EmptyFormation />
        ) : (
          <TrainingTable
            onUnregisterConfirm={async training => {
              if (training.nbSeats === 1) {
                await unregisterFromIndividualTraining({
                  variables: { trainingId: training.id, userId: user?.id },
                });
              } else {
                await unregisterFromCollectiveTraining({
                  variables: {
                    trainingId: training.id,
                    userId: user?.id,
                    nbSeatsAvailable: training.nbSeatsAvailable + 1,
                  },
                });
              }

              lazyMyTrainings();
              sendUnregisterEmail({
                variables: {
                  trainingId: training?.id,
                  userEmail: user?.email,
                  trainingName: training?.name,
                  trainingStart: training?.dateStart,
                  trainingEnd: training?.dateEnd,
                  trainingAddress: training?.address,
                  trainingPostalCode: training.postalCode,
                  trainingCity: training.city,
                  regionManager: `${training.region.user.firstName} ${training.region.user.lastName}`,
                  regionName: training.region.name,
                },
              });
            }}
            trainings={data?.nextTraining}
            loading={!called || loading}
            emptyText={t('new-training.my-trainings.text')}
          />
        )}

        <Spacer />

        <Typography.Title level={3} className={style.subtitle}>
          {t('new-training.last-trainings.subtitle')}
        </Typography.Title>

        <Typography.Text>
          {t('new-training.last-trainings.subtitleExplained')}
        </Typography.Text>

        <Spacer />

        <TrainingTable
          trainings={data?.lastTraining}
          loading={!called || loading}
          emptyText={t('new-training.last-trainings.text')}
        />
      </div>
    </ConnectedPage>
  );
}

export default Trainings;
