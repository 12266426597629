import { Auth0DecodedHash, WebAuth } from 'auth0-js';
import config from 'config/index';
import { setUserId } from 'technical/analytics';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';

// TODO: use use-auth-react https://github.com/Swizec/useAuth
export const webAuth = new WebAuth({
  ...config.auth0,
  responseType: 'token id_token',
  scope: 'openid profile email',
});

// Auth0 Authorization object containing accessToken. Do not put in localStorage
let authResult: Auth0DecodedHash | null = null;

export function getAuthResult() {
  return authResult;
}

export function getAccessToken() {
  return authResult?.idToken;
}

export function getAuthId() {
  return authResult?.idTokenPayload.sub;
}

export function isAuthenticated() {
  return !!authResult;
}

// Typed as ay as per Auth0 types
async function persistAuth(newAuthResult: Auth0DecodedHash) {
  authResult = newAuthResult;
}

function unpersistAuth() {
  authResult = null;
}

export function logout() {
  unpersistAuth();
  setUserId(undefined);
  // Suppression des filtres des tableaux d'épisodes
  // localStorage.removeItem('filters')
  // auth0 removes its cookies for silent reconnect and redirect to configured page = /
  webAuth.logout({ returnTo: config.auth0.logoutUri });
}

export function login() {
  // Suppression des filtres des tableaux d'épisodes
  localStorage.removeItem('filters');
  webAuth.authorize({
    mode: 'login',
    // if prompt === 'login', force reprompt, else, try to silently reconnect
    prompt: 'login',
  });
}

export function signUp() {
  webAuth.authorize({
    mode: 'signUp',
    // @ts-ignore https://community.auth0.com/t/how-to-change-initial-screen-to-sign-up-using-auth0-spa-js-universal-login/32941/7
    screen_hint: 'signup',
    // if prompt === 'login', force reprompt, else, try to silently reconnect
    prompt: 'login',
  });
}

export function renewToken(): Promise<any> {
  return new Promise((resolve, reject) => {
    webAuth.checkSession({}, function onCheckSession(
      auth0error,
      newAuthResult,
    ) {
      logger.info('Auth status', auth0error, !!newAuthResult);
      if (auth0error) {
        // Suppression des filtres des tableaux d'épisodes
        localStorage.removeItem('filters');
        return reject(auth0error);
      }

      persistAuth(newAuthResult);
      return resolve(newAuthResult);
    });
  });
}

export function requestLoginCallback() {
  return new Promise((resolve, reject) =>
    webAuth.parseHash((err, newAuthResult) => {
      if (err) {
        // error.errorDescription contains an error key
        // - invalid-email-domain
        // - email-not-verified
        // Or a generic error message
        logger.error(err);
        return reject(err);
      }

      if (newAuthResult && newAuthResult.accessToken && newAuthResult.idToken) {
        logger.info('newAuthResult', newAuthResult);
        persistAuth(newAuthResult);

        return resolve();
      }
      return reject(new Error('An error occured during authentication'));
    }),
  );
}

export const isAuthInitialized = new Promise(resolve =>
  renewToken()
    .catch(err => {
      if (err.code !== 'login_required') {
        errorReporting.error(err);
      }
    })
    .finally(resolve),
);
