import gql from 'graphql-tag';

export const GET_SITE = gql`
  query GetSites($origin: String, $regionId: uuid) {
    site(where: { origin: { _eq: $origin }, regionId: { _eq: $regionId } }) {
      id
      organisationName
      address
      postalCode
      city
    }
  }
`;

type Site = {
  id: string;
  organisationName: string;
  address: string;
  postalCode: string;
  city: string;
};

export type SiteData = {
  site: Site[];
};
