import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Table, Typography, Checkbox, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import history from 'technical/history';
import logger from 'technical/logger';
import Title from 'ui/title';
import Spacer from 'ui/spacer';
import Button from 'ui/button';
import ConnectedPage from 'business/layout/connectedPage';
import { useAppContext } from 'business/provider';
import { userTypeToTrainingType } from 'business/user/services/training';
import getTime from 'technical/dateFormatter/dateFormatter';
import {
  NewTrainingData,
  GET_NEW_TRAININGS,
  REGISTER_TO_TRAINING,
  CREATE_INDIVIDUAL_TRAINING,
  CreateTraniningData,
  SEND_REGISTER_EMAIL,
} from './api';
import style from './index.module.scss';

function TrainingRegistration() {
  const { t } = useTranslation();
  const { user } = useAppContext();
  const [now] = useState(new Date());
  now.setHours(0, 0);
  const [checkedIndividual, setCheckedIndividual] = useState(false);
  const [trainingId, setTrainingId] = useState<string>();
  const [success, setSuccess] = useState(false);
  const { data, loading } = useQuery<NewTrainingData>(GET_NEW_TRAININGS, {
    variables: {
      now,
      type: userTypeToTrainingType(user!),
      userId: user?.id,
      regionId: user?.regionId,
    },
  });
  const [registerToTraining] = useMutation(REGISTER_TO_TRAINING);
  const [createIndividualTraining] = useMutation<CreateTraniningData>(
    CREATE_INDIVIDUAL_TRAINING,
  );
  const [sendRegisterEmail] = useMutation(SEND_REGISTER_EMAIL);

  if (success) {
    return (
      <ConnectedPage>
        <Result
          status="success"
          title={t('new-training.registration.success.title')}
          subTitle={t('new-training.registration.success.subTitle')}
          extra={
            <Button onClick={() => history.push('/new-trainings')}>
              {t('new-training.registration.success.redirectButton')}
            </Button>
          }
        />
      </ConnectedPage>
    );
  }

  return (
    <ConnectedPage>
      <Title title={t(`new-training.registration.title`)} />

      <Spacer />

      {user?.type === 'new' ? (
        <Typography.Text>{t('new-training.registration.text')}</Typography.Text>
      ) : (
        <Typography.Text>
          Le tableau ci-dessous vous permet de choisir une formation. Pour vous
          inscrire, cliquez sur la case correspondante dans la dernière colonne,
          puis cliquez sur “S’inscrire à la formation sélectionnée”.
        </Typography.Text>
      )}

      <Spacer />

      <Typography.Title level={3}>
        {t('new-training.registration.collective')}
      </Typography.Title>

      <Table
        rowKey="id"
        locale={{
          emptyText: "Il n'y a pas de formation de prévue prochainement",
        }}
        loading={loading}
        pagination={false}
        dataSource={data?.training}
        columns={[
          {
            title: t('new-training.table.name'),
            dataIndex: 'name',
          },
          {
            title: t('new-training.table.address'),
            dataIndex: 'address',
            render: (_, row) => {
              return `${row.address} ${row.postalCode} ${row.city}`;
            },
          },
          {
            title: t('new-training.table.date'),
            dataIndex: 'dateStart',
            render: (_, row) => {
              const formatter = Intl.DateTimeFormat('fr-FR', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              });

              return `${formatter.format(new Date(row.dateStart))}`;
            },
          },
          {
            title: t('new-training.table.hour'),
            dataIndex: 'dateEnd',
            render: (_, row) => {
              const dateStart = new Date(row.dateStart);
              const dateEnd = new Date(row.dateEnd);

              return `${getTime(dateStart)}-${getTime(dateEnd)}`;
            },
          },
        ]}
        rowSelection={{
          type: 'radio',
          onSelect: ({ id }) => {
            setTrainingId(id);
            setCheckedIndividual(false);
          },
          selectedRowKeys: trainingId ? [trainingId] : [],
        }}
      />

      <Spacer />

      <Typography.Title level={3}>
        {t('new-training.registration.individual')}
      </Typography.Title>

      <Spacer />

      <Checkbox
        checked={checkedIndividual}
        onChange={value => {
          setCheckedIndividual(value.target.checked);
          setTrainingId(undefined);
        }}
      >
        {t('new-training.registration.checkbox')}
      </Checkbox>

      <Spacer />

      <Button
        type="primary"
        disabled={!trainingId && !checkedIndividual}
        className={style.btn}
        onClick={async () => {
          try {
            let selectedTraining = data?.training.find(
              training => training.id === trainingId,
            );

            if (checkedIndividual) {
              const { data: trainingData } = await createIndividualTraining({
                variables: {
                  regionId: user?.regionId,
                  type: user?.type === 'new' ? 'new' : 'renewal',
                },
              });

              if (!trainingData) {
                throw new Error('cant create a new training');
              }

              [selectedTraining] = trainingData.insert_training.returning;
            }

            if (!selectedTraining) {
              throw new Error('cant retrieve the training');
            }

            await registerToTraining({
              variables: {
                userId: user?.id,
                trainingId: selectedTraining?.id,
                nbSeatsAvailable: selectedTraining.nbSeatsAvailable - 1,
              },
            });

            sendRegisterEmail({
              variables: {
                trainingId: selectedTraining?.id,
                userEmail: user?.email,
                trainingName: selectedTraining?.name,
                trainingStart: selectedTraining?.dateStart,
                trainingEnd: selectedTraining?.dateEnd,
                trainingAddress: selectedTraining?.address,
                trainingPostalCode: selectedTraining.postalCode,
                trainingCity: selectedTraining.city,
                regionManager: `${selectedTraining.region.user.firstName} ${selectedTraining.region.user.lastName}`,
                regionName: selectedTraining.region.name,
              },
            });
            setSuccess(true);
          } catch (e) {
            logger.error(e);
          }
        }}
      >
        {t('new-training.registration.submit')}
      </Button>
    </ConnectedPage>
  );
}

export default TrainingRegistration;
