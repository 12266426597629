import React from 'react';
import layout from '../../config/layout';
import FullNavBar from './components/fullNavBar';
import StandardNavBar, { Section } from './components/standardNavBar';

interface Props {
  isConnected?: boolean;
  logoSections?: Section[];
}

export default function NavBar({ isConnected, logoSections }: Props) {
  switch (layout.header.type) {
    case 'full':
      return <FullNavBar isConnected={isConnected} />;
    case 'standard':
    default:
      return (
        <StandardNavBar isConnected={isConnected} logoSections={logoSections} />
      );
  }
}
