import { ColumnType } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import React from 'react';
import { useAppContext } from '../../provider';
import { useMemoryColumn } from '../TabContext';
import Button from '../../../ui/button';
import styles from './index.module.scss';
import sortFunction from '../../../technical/sort';
import { ColumnProps, Row } from '../types/EpisodeTableTypes';

export default function useExpertColumn(props: ColumnProps): ColumnType<Row> {
  const { user } = useAppContext();
  const { t } = useTranslation();
  return {
    ...useMemoryColumn(props.idTable, 'expert'),
    title: t('episode.my.table.expert'),
    render: (_, row) => {
      if (!row.episode.expert) return '-';
      if (row.episode.expert.id === user?.id) {
        return `${row.episode.expert.firstName} ${row.episode.expert.lastName}`;
      }
      return (
        <Button
          type="link"
          className={styles.buttonLink}
          onClick={() => {
            props.setModal({
              visible: true,
              title: `${row.episode.expert?.firstName} ${row.episode.expert?.lastName}`,
              content: (
                <>
                  {row.episode.expert?.email && (
                    <Typography.Paragraph>
                      {t('episode.my.modal.email', {
                        value: row.episode.expert.email,
                      })}
                    </Typography.Paragraph>
                  )}
                  {row.episode.expert?.phone && (
                    <Typography.Paragraph>
                      {t('episode.my.modal.phone', {
                        value: row.episode.expert.phone,
                      })}
                    </Typography.Paragraph>
                  )}
                </>
              ),
            });
          }}
        >
          {`${row.episode.expert.firstName} ${row.episode.expert.lastName}`}
        </Button>
      );
    },
    sorter: (a, b) =>
      sortFunction(a.episode?.expert?.lastName, b.episode?.expert?.lastName),
  };
}
