import React, { useMemo, useState } from 'react';
import { Modal, Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { SorterResult } from 'antd/es/table/interface';
import { PaginationConfig } from 'antd/lib/pagination';
import { Episode, PackData } from '../api';
import { useTableContext } from '../TabContext';
import { useAppContext } from '../../provider';
import useAnimatorColumn from './animator-column';
import useActionsColumn from './actions-column';
import useExpertColumn from './expert-column';
import {
  useEstablishmentColumn,
  useEstablishmentAddressColumn,
  useClassNameColumn,
  useLevelColumn,
  useTypeColumn,
  useEpisodeColumn,
  useDateColumn,
  useHourColumn,
  useRegionColumn,
} from './columns-function';
import { ColumnProps, Row } from '../types/EpisodeTableTypes';

interface EpisodesTableProps {
  emptyText: string;
  isLoading: boolean;
  idTable: string;
  packsData?: PackData;
  evaluationsData?: {
    speakerEvaluation: {
      id: string;
      episode_id: string;
    }[];
  };
  onUnregisterConfirm?: (row: Row) => void;
  shouldDisplayEpisode: (episode: Episode) => boolean;
}
const EpisodesTable: React.FC<EpisodesTableProps> = ({
  onUnregisterConfirm = () => {},
  packsData,
  idTable,
  evaluationsData,
  shouldDisplayEpisode,
  emptyText,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { user } = useAppContext();
  const { update } = useTableContext();

  const [modal, setModal] = useState<{
    visible?: boolean;
    title?: string;
    content?: React.ReactNode;
  }>({});

  const onTableChanged = (
    _pagination: PaginationConfig,
    filters: Record<string, React.ReactText[] | null>,
    sorter: SorterResult<Row> | SorterResult<Row>[],
  ): void => {
    const sorterType = sorter as SorterResult<Row>;
    update({
      sort: sorterType.order,
      idTable,
      filters: filters as Record<string, string[]>,
      key: sorterType?.column?.key as string,
    });
  };

  const columnsProperties: ColumnProps = {
    packsData,
    idTable,
    setModal,
    evaluationsData,
    onUnregisterConfirm,
  };

  const packs = useMemo(() => {
    if (!packsData) {
      return undefined;
    }
    return packsData.class.reduce<Row[]>((acc, c) => {
      [0, 1, 2].forEach(episodeNumber => {
        // @ts-ignore
        const episode: Episode = c.episodes[episodeNumber];

        if (
          episode &&
          (episode.animator?.id === user?.id ||
            episode.animator_2?.id === user?.id ||
            episode.expert?.id === user?.id) &&
          shouldDisplayEpisode(episode)
        ) {
          acc.push({
            key: c.key,
            name: c.name,
            type: c.type,
            level: c.level,
            establishment: c.establishment,
            episode,
          });
        }
      });

      return acc;
    }, []);
  }, [packsData, shouldDisplayEpisode]);

  return (
    <>
      <Modal
        visible={modal.visible}
        title={modal.title}
        footer={null}
        closeIcon={
          <Typography.Text type="warning">
            {t('episode.my.modal.close')}
          </Typography.Text>
        }
        onCancel={() => setModal({ ...modal, visible: false })}
      >
        {modal.content}
      </Modal>
      <Table
        locale={{ emptyText }}
        style={{ whiteSpace: 'pre' }}
        loading={isLoading}
        pagination={{
          pageSizeOptions: ['10', '25', '50'],
          showSizeChanger: true,
        }}
        onChange={onTableChanged}
        dataSource={packs}
        columns={[
          useEstablishmentColumn(columnsProperties),
          useRegionColumn(columnsProperties),
          useEstablishmentAddressColumn(columnsProperties),
          useClassNameColumn(columnsProperties),
          useLevelColumn(columnsProperties),
          useTypeColumn(columnsProperties),
          useEpisodeColumn(columnsProperties),
          useDateColumn(columnsProperties),
          useHourColumn(columnsProperties),
          useAnimatorColumn(columnsProperties),
          useExpertColumn(columnsProperties),
          useActionsColumn(columnsProperties),
        ]}
      />
    </>
  );
};

export default EpisodesTable;
