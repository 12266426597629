import { useAppContext } from 'business/provider';
import * as React from 'react';
import { Spin } from 'antd';
import Flex from 'ui/flex';

interface Props {
  children: React.ReactNode;
}

function AppBootstrapper({ children }: Props) {
  const { appBootstraped } = useAppContext();

  return appBootstraped ? (
    (children as React.ReactElement)
  ) : (
    <Flex
      alignItems="center"
      justify="center"
      style={{ flex: 1, height: '100vh' }}
    >
      <Spin size="large" />
    </Flex>
  );
}

export default AppBootstrapper;
