import { i18n } from 'translations';

type Section = {
  label: string;
  path: string;
  allowedRoles: string[];
};

type Layout = {
  header: {
    type: 'full' | 'standard';
    menuSections: Section[];
  };
};

const layout: Layout = {
  header: {
    type: 'full', // "standard", "full"
    menuSections: [
      {
        label: i18n.t('header.training-register'),
        path: '/new-training-registration',
        allowedRoles: ['new', 'animator', 'expert'],
      },
      {
        label: i18n.t('header.trainings'),
        path: '/new-trainings',
        allowedRoles: ['new', 'animator', 'expert'],
      },
      {
        label: i18n.t('header.episodes-register'),
        path: '/episodes-registration',
        allowedRoles: ['animator', 'expert'],
      },
      {
        label: i18n.t('header.episodes'),
        path: '/episodes',
        allowedRoles: ['animator', 'expert'],
      },
      {
        label: i18n.t('header.educational_resources'),
        path:
          'https://drive.google.com/drive/folders/1pj1su6hhGfLeVWgiWzSzOM7LSMSjx6vg?usp=sharing',
        allowedRoles: ['animator', 'expert'],
      }
    ],
  },
};

export default layout;
