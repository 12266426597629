import React, { useState } from 'react';
import ConnectedPage from 'business/layout/connectedPage';
import Title from 'ui/title';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import Spacer from 'ui/spacer';
import { useTranslation } from 'react-i18next';
import EpisodesResult from './EpisodesResult';
import EpisodesSearch from './EpisodesSearch';
import {
  EpisodeSearchStateDefault,
  EpisodesSearchState,
} from '../types/EpisodesSearchState';

export default function EpisodesRegistration() {
  const { t } = useTranslation();
  const [formState, setFormState] = useState<EpisodesSearchState>(
    EpisodeSearchStateDefault,
  );

  return (
    <ConnectedPage>
      <Title title={t('episode.registration.title')} />
      <Spacer />
      <Typography.Title level={4}>
        {t('episode.registration.search.title')}
      </Typography.Title>
      <Typography.Text>
        {t('episode.registration.search.text')}{' '}
      </Typography.Text>
      <Spacer />
      <EpisodesSearch
        setState={setFormState}
        state={formState}
        disabled={false}
      />
      <Spacer />
      <Typography.Title level={4}>
        {t('episode.registration.results.title')}
      </Typography.Title>
      <Typography.Text>
        {t('episode.registration.results.text')}{' '}
        <Link to="/episodes">{t('episode.registration.results.link')}</Link>.
      </Typography.Text>
      <Spacer />
      <EpisodesResult search={formState} />
    </ConnectedPage>
  );
}
