import React, { useEffect } from 'react';
import ConnectedPage from 'business/layout/connectedPage';
import { useTranslation } from 'react-i18next';
import Title from 'ui/title';
import Spacer from 'ui/spacer';
import { Typography, Space, DatePicker } from 'antd';
import 'moment/locale/fr';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useAppContext } from 'business/provider';
import { GET_MY_EVALUATIONS } from 'business/evaluation/speaker-evaluation/api';
import moment from 'moment';
import {
  GET_MY_PACKS,
  PackData,
  UNREGISTER_TO_EPISODE,
  SEND_UNREGISTER_EMAIL,
} from '../api';
import styles from './index.module.scss';
import { getCurrentScholarYear } from '../../user/services/training';
import EpisodesTable from './episodes-table';

import formatEpisodeDate from '../../../technical/formatDate/formatDate';

moment.locale('fr');

const { RangePicker } = DatePicker;
export default function Episodes() {
  const { t } = useTranslation();
  const { user } = useAppContext();
  const [firstDate, lastDate] = getCurrentScholarYear();
  const [
    lazyMyPacks,
    { called: calledPacks, loading: loadingPacks, data: packsData },
  ] = useLazyQuery<PackData>(GET_MY_PACKS, { fetchPolicy: 'network-only' });
  const [
    lazyMyEvaluations,
    {
      called: calledEvalutions,
      loading: loadingEvaluations,
      data: evaluationsData,
    },
  ] = useLazyQuery(GET_MY_EVALUATIONS, { fetchPolicy: 'network-only' });
  const [unregisterToEpisode] = useMutation(
    UNREGISTER_TO_EPISODE(
      user?.type === 'expert' ? 'expertId' : 'firstAnimateurId',
    ),
  );
  const [sendUnregisterEmail] = useMutation(SEND_UNREGISTER_EMAIL);

  useEffect(() => {
    if (user) {
      const [dateStart, dateEnd] = getCurrentScholarYear();
      lazyMyPacks({
        variables: { userId: user.id, dateStart, dateEnd },
      });
      lazyMyEvaluations({ variables: { userId: user.id } });
    }
  }, [user, lazyMyPacks, lazyMyEvaluations]);
  const dateFormat = 'DD/MM/YYYY';
  return (
    <ConnectedPage>
      <Title title={t('episode.my.title')} />
      <Spacer />
      <Space size={25} className={styles.rangePicker}>
        <RangePicker
          defaultValue={[moment(firstDate), moment(lastDate)]}
          size="large"
          format={dateFormat}
          onChange={([dateStart, dateEnd]) => {
            lazyMyPacks({
              variables: { userId: user?.id, dateStart, dateEnd },
            });
          }}
        />
      </Space>
      <Typography.Title level={4}>
        {t('episode.my.next.title')}
      </Typography.Title>
      <span>
        <Typography.Text>{t('episode.my.next.text.prefix')}</Typography.Text>
        <Typography.Text type="danger">
          {t('episode.my.next.text.value')}
        </Typography.Text>
        <Typography.Text>{t('episode.my.next.text.suffix')}</Typography.Text>
      </span>
      <EpisodesTable
        idTable="upcoming-episodes"
        isLoading={
          !(
            calledPacks &&
            !loadingPacks &&
            calledEvalutions &&
            !loadingEvaluations
          )
        }
        packsData={packsData}
        evaluationsData={evaluationsData}
        shouldDisplayEpisode={e =>
          e.dateEnd === null || new Date(e.dateEnd) >= new Date()
        }
        emptyText="Vous n'avez pas d'épisode de prévu"
        onUnregisterConfirm={async row => {
          const episodeId = row.episode.id;
          const regionManager = row.establishment.region.user;
          await unregisterToEpisode({ variables: { episodeId } });
          lazyMyPacks({ variables: { userId: user?.id } });
          sendUnregisterEmail({
            variables: {
              id: row.episode.id,
              recipient: user?.email,
              episodeNumber: row.episode.number,
              className: row.name,
              establishmentName: row.establishment.name,
              regionManager: `${regionManager.firstName} ${regionManager.lastName}`,
              episodeDate: formatEpisodeDate(row.episode),
            },
          });
        }}
      />

      <Spacer />
      <Typography.Title level={4}>
        {t('episode.my.past.title')}
      </Typography.Title>
      <span>
        <Typography.Text>{t('episode.my.past.text.prefix')}</Typography.Text>
        <Typography.Text type="warning">
          {t('episode.my.past.text.value')}
        </Typography.Text>
        <Typography.Text>{t('episode.my.past.text.suffix')}</Typography.Text>
      </span>
      <EpisodesTable
        idTable="passed-episodes"
        isLoading={
          !(
            calledPacks &&
            !loadingPacks &&
            calledEvalutions &&
            !loadingEvaluations
          )
        }
        packsData={packsData}
        evaluationsData={evaluationsData}
        shouldDisplayEpisode={e =>
          e.dateEnd !== null && new Date(e.dateEnd) < new Date()
        }
        emptyText="Vous n'avez pas encore participé à un épisode"
      />
    </ConnectedPage>
  );
}
