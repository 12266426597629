import React, { useState, useCallback } from 'react';
import {
  Row,
  Col,
  Button,
  Typography,
  AutoComplete,
  DatePicker,
  Select,
  Input,
} from 'antd';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import AddressInput, {
  emptyGeoAddress,
  GeoAddress,
} from 'ui/form/addressInput/index';
import Flex from 'ui/flex';
import moment from 'moment';
import { DownOutlined } from '@ant-design/icons';
import { useAppContext } from 'business/provider';
import styles from './episodes-search.module.scss';
import colors from '../../../config/colors';
import { Establishment, GET_ESTABLISHMENTS } from '../api';
import {
  EpisodesSearchState,
  EpisodesSearchModes,
  EpisodeSearchStateDefault,
} from '../types/EpisodesSearchState';

type Props = {
  disabled: boolean;
  state: EpisodesSearchState;
  setState: React.Dispatch<React.SetStateAction<EpisodesSearchState>>;
};

export default function EpisodesSearch({ disabled, state, setState }: Props) {
  const [options, setOptions] = useState<Establishment[]>([]);
  const { loading, data } = useQuery<{ establishment: Establishment[] }>(
    GET_ESTABLISHMENTS,
  );
  const [formState, setFormState] = useState<EpisodesSearchState>(state);
  const [addressState, setAddressState] = useState<GeoAddress>(emptyGeoAddress);
  const { t } = useTranslation();
  const { user } = useAppContext();
  const onAddressClear = useCallback(() => {
    setFormState(fs => ({
      ...fs,
      location: undefined,
    }));
  }, []);
  const onAddressChange = useCallback(datas => {
    const {
      coordinates: { latitude, longitude },
    } = datas;
    setFormState(fs => ({
      ...fs,
      location: {
        lat: latitude,
        lon: longitude,
      },
      establishment: undefined,
    }));
    setAddressState(datas);
  }, []);

  const onEstablishmentSearch = (search: string) => {
    if (!data || !data.establishment) {
      setOptions([]);
      return;
    }
    setOptions(
      data.establishment.filter(establishment =>
        establishment.name.toLowerCase().includes(search.toLowerCase()),
      ),
    );
  };

  const onCityClear = useCallback(() => {
    setFormState(fs => ({
      ...fs,
      city: undefined,
    }));
  }, []);
  const onCityChange = useCallback(({ input }) => {
    setFormState(fs => ({
      ...fs,
      city: input,
    }));
  }, []);

  const onPostalCodeChange = (postalCode?: string) => {
    setFormState(fs => ({
      ...fs,
      postalCode,
    }));
  };

  const reset = () => {
    setAddressState(emptyGeoAddress);
    setFormState(EpisodeSearchStateDefault);
    setState(EpisodeSearchStateDefault);
  };

  return (
    <div
      style={{
        paddingTop: 24,
        paddingLeft: 24,
        paddingRight: 24,
        borderStyle: 'solid',
        borderColor: colors.primaryColor,
        borderWidth: 1,
        borderRadius: 4,
        backgroundColor: colors.white,
      }}
    >
      <Row style={{ marginBottom: 24 }}>
        <Col span={11}>
          <div>
            <Typography.Text strong>
              Sélectionner vos critères géographiques de recherche :
            </Typography.Text>
          </div>
        </Col>
        <Col span={2} style={{ textAlign: 'center' }}>
          <Typography.Text strong>et / ou</Typography.Text>
        </Col>
        <Col span={11}>
          <div>
            <Typography.Text strong>
              Sélectionner vos critères temporels de recherche :
            </Typography.Text>
          </div>
        </Col>
      </Row>

      <Row justify="space-around" style={{ marginBottom: 24 }}>
        <Col span={11}>
          <Row>
            <Col span={24}>
              <Select
                className={styles.selectSearchType}
                suffixIcon={
                  <DownOutlined className={styles.selectSearchTypeArrow} />
                }
                onChange={searchType =>
                  setFormState({
                    ...formState,
                    searchType,
                    regionId: undefined
                  })
                }
                defaultValue="address"
                dropdownMatchSelectWidth={false}
                value={formState.searchType}
              >
                {Object.entries(EpisodesSearchModes).map(
                  ([searchMode, label]) => (
                    <Select.Option key={searchMode} value={searchMode}>
                      {label}
                    </Select.Option>
                  ),
                )}
              </Select>
              <Flex alignItems="center">
                {formState.searchType === 'address' && (
                  <>
                    <AddressInput
                      onClear={onAddressClear}
                      onChange={onAddressChange}
                      value={addressState}
                    />
                    <Typography.Text
                      style={{ margin: 10, whiteSpace: 'nowrap' }}
                    >
                      dans un rayon de
                    </Typography.Text>
                    <Select
                      placeholder="Distance"
                      onChange={distance =>
                        setFormState({
                          ...formState,
                          distance,
                          establishment: undefined,
                        })
                      }
                      bordered={false}
                      value={formState.distance}
                    >
                      <Select.Option value="50">0 km</Select.Option>
                      <Select.Option value="5000">5 km</Select.Option>
                      <Select.Option value="10000">10 km</Select.Option>
                      <Select.Option value="20000">20 km</Select.Option>
                      <Select.Option value="30000">30 km</Select.Option>
                      <Select.Option value="40000">40 km</Select.Option>
                      <Select.Option value="null">+ de 40 km</Select.Option>
                    </Select>
                  </>
                )}
                {formState.searchType === 'establishment' && (
                  <AutoComplete
                    style={{ width: '100%' }}
                    allowClear
                    onChange={value => {
                      setFormState({
                        ...formState,
                        ...(!value ? { establishment: undefined } : {}),
                      });
                    }}
                    onSelect={(_value: string, option: any) =>
                      setFormState({
                        ...formState,
                        establishment: option.key,
                        location: undefined,
                      })
                    }
                    onSearch={onEstablishmentSearch}
                    disabled={loading}
                    placeholder={t(
                      'episode.registration.search.establishment-placeholder',
                    )}
                  >
                    {options.map(option => (
                      <AutoComplete.Option
                        key={option.id}
                        value={`${option.name} - ${option.city}`}
                      >
                        {`${option.name} - ${option.city}`}
                      </AutoComplete.Option>
                    ))}
                  </AutoComplete>
                )}
                {formState.searchType === 'city' && (
                  <AddressInput
                    onClear={onCityClear}
                    onChange={onCityChange}
                    type="city"
                  />
                )}
                {formState.searchType === 'postalCode' && (
                  <Input
                    maxLength={5}
                    onChange={event => {
                      onPostalCodeChange(event.target.value);
                    }}
                    placeholder={t(
                      'episode.registration.search.postal-code-placeholder',
                    )}
                  />
                )}
                {formState.searchType === 'region' && (
                  <>
                    <Select
                      style={{ width: 150 }}
                      className={styles.selectSearchType}
                      suffixIcon={<DownOutlined className={styles.selectSearchTypeArrow} />}
                      placeholder="Région"
                      onChange={regionId =>
                        setFormState({
                          ...formState,
                          regionId,
                          establishment: undefined,
                        })
                      }
                      value={formState.regionId}
                    >
                      {user?.regionsAnimation?.map(r => (
                        <Select.Option key={r.id} value={r.id}>
                          {r.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </>
                )}
              </Flex>
            </Col>
          </Row>
          <Row style={{ marginTop: 24 }}>
            <Col span={24}>
              {formState.regionId && (
                <>
                  <Select
                    className={styles.selectSearchType}
                    suffixIcon={<DownOutlined className={styles.selectSearchTypeArrow} />}
                    onChange={(regionSearchType) =>
                      setFormState({
                        ...formState,
                        regionSearchType,
                      })
                    }
                    defaultValue="address"
                    dropdownMatchSelectWidth={false}
                    value={formState.regionSearchType}
                  >
                    {Object.entries(EpisodesSearchModes)
                      .filter(([searchMode]) => searchMode !== 'region')
                      .map(([searchMode, label]) => (
                        <Select.Option key={searchMode} value={searchMode}>
                          {label}
                        </Select.Option>
                      ))}
                  </Select>
                  <Flex alignItems="center">
                    {formState.regionSearchType === 'address' && (
                      <>
                        <AddressInput
                          onClear={onAddressClear}
                          onChange={onAddressChange}
                          value={addressState}
                        />
                        <Typography.Text style={{ margin: 10, whiteSpace: 'nowrap' }}>
                          dans un rayon de
                        </Typography.Text>
                        <Select
                          placeholder="Distance"
                          onChange={(distance) =>
                            setFormState({
                              ...formState,
                              distance,
                              establishment: undefined,
                            })
                          }
                          bordered={false}
                          value={formState.distance}
                        >
                          <Select.Option value="50">0 km</Select.Option>
                          <Select.Option value="5000">5 km</Select.Option>
                          <Select.Option value="10000">10 km</Select.Option>
                          <Select.Option value="20000">20 km</Select.Option>
                          <Select.Option value="30000">30 km</Select.Option>
                          <Select.Option value="40000">40 km</Select.Option>
                          <Select.Option value="null">+ de 40 km</Select.Option>
                        </Select>
                      </>
                    )}
                    {formState.regionSearchType === 'establishment' && (
                      <AutoComplete
                        style={{ width: '100%' }}
                        allowClear
                        onChange={(value) => {
                          setFormState({
                            ...formState,
                            ...(!value ? { establishment: undefined } : {}),
                          });
                        }}
                        onSelect={(_value: string, option: any) =>
                          setFormState({
                            ...formState,
                            establishment: option.key,
                            location: undefined,
                          })
                        }
                        onSearch={onEstablishmentSearch}
                        disabled={loading}
                        placeholder={t(
                          'episode.registration.search.establishment-placeholder'
                        )}
                      >
                        {options.filter(
                          option => option.region.id === formState.regionId
                        ).map((option) => (
                          <AutoComplete.Option
                            key={option.id}
                            value={`${option.name} - ${option.city}`}
                          >
                            {`${option.name} - ${option.city}`}
                          </AutoComplete.Option>
                        ))}
                      </AutoComplete>
                    )}
                    {formState.regionSearchType === 'city' && (
                      <AddressInput
                        onClear={onCityClear}
                        onChange={onCityChange}
                        type="city"
                      />
                    )}
                    {formState.regionSearchType === 'postalCode' && (
                      <Input
                        maxLength={5}
                        onChange={(event) => {
                          onPostalCodeChange(event.target.value);
                        }}
                        placeholder={t(
                          'episode.registration.search.postal-code-placeholder'
                        )}
                      />
                    )}
                  </Flex>
                </>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={2}>
          <div
            className={styles.divider}
            style={{ backgroundColor: colors.normalColor }}
          />
        </Col>
        <Col span={11}>
          <Row>
            <Col span={24}>
              <DatePicker
                value={formState.dateStart ? moment(formState.dateStart) : null}
                onChange={date =>
                  date
                    ? setFormState({
                        ...formState,
                        dateStart: date
                          .utc()
                          .startOf('day')
                          .toDate(),
                      })
                    : setFormState({ ...formState, dateStart: undefined })
                }
                format="DD-MM-YYYY"
                name="dateStart"
                placeholder="À partir de"
                style={{ width: 140 }}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: 18, marginBottom: 40 }}>
            <Col span={24}>
              <Select<'morning' | 'afternoon'>
                options={[
                  {
                    value: 'morning',
                    label: t('episode.search.preferredTime.morning'),
                  },
                  {
                    value: 'afternoon',
                    label: t('episode.search.preferredTime.afternoon'),
                  },
                ]}
                value={formState.preferredTime}
                placeholder={t('episode.search.preferredTime.placeholder')}
                style={{ width: 140 }}
                onChange={preferredTime =>
                  setFormState({ ...formState, preferredTime })
                }
                allowClear
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginBottom: 24 }}>
        <Col span={24}>
          <Button
            type="primary"
            onClick={() => setState(formState)}
            disabled={disabled}
            className={styles.submit}
          >
            Rechercher des épisodes sur la base de ces critères
          </Button>
          <Button type="ghost" onClick={reset} className={styles.reset}>
            {t('episode.search.reset')}
          </Button>
        </Col>
      </Row>
    </div>
  );
}
