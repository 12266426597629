if (!process.env.REACT_APP_AUTH0_DOMAIN) {
  throw new Error('Set REACT_APP_AUTH0_DOMAIN');
}

if (!process.env.REACT_APP_AUTH0_CLIENT_ID) {
  throw new Error('Set REACT_APP_AUTH0_CLIENT_ID');
}

if (!process.env.REACT_APP_AUTH0_REDIRECT_URI) {
  throw new Error('Set REACT_APP_AUTH0_REDIRECT_URI');
}

if (!process.env.REACT_APP_GRAPHQL_URI) {
  throw new Error('Set REACT_APP_GRAPHQL_URI');
}

if (!process.env.REACT_APP_GRAPHQL_WS_URI) {
  throw new Error('Set REACT_APP_GRAPHQL_WS_URI');
}

if (!process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
  throw new Error('Set REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID');
}

if (!process.env.REACT_APP_LINK_VOLUNTEER_CONVENTION) {
  throw new Error('Set REACT_APP_LINK_VOLUNTEER_CONVENTION');
}

if (!process.env.REACT_APP_LINK_RGPD) {
  throw new Error('Set REACT_APP_LINK_RGPD');
}

const config = {
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
    logoutUri: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URI,
  },
  graphqlUri: process.env.REACT_APP_GRAPHQL_URI,
  graphqlWsUri: process.env.REACT_APP_GRAPHQL_WS_URI,
  analytics: {
    enabled:
      process.env.REACT_APP_ANALYTICS_ENABLED === 'true' ||
      process.env.REACT_APP_ENVIRONMENT === 'production' ||
      process.env.REACT_APP_ENVIRONMENT === 'test',

    ga: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID,
  },
  contact: {
    email: 'matters@matters.tech',
    address: '10, rue du Faubourg Poissonnière, 75010 Paris',
    phone: '+33 9 72 64 96 09',
    credits: '© 2020 Matters - tous droits réservés',
  },
  links: {
    volunteerConvention: process.env.REACT_APP_LINK_VOLUNTEER_CONVENTION,
    rgpd: process.env.REACT_APP_LINK_RGPD,
  },
};

export default config;
