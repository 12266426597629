import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Checkbox, Table } from 'antd';
import { User } from 'business/user/types';
import Flex from 'ui/flex';
import getTime from 'technical/dateFormatter/dateFormatter';
import sortFunction from 'technical/sort/index';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import {
  Episode,
  Pack,
  REGISTER_TO_EPISODES,
  SEND_REGISTER_EMAIL,
  importExportTypeClasses,
} from '../api';
import { arePacksOverlapping } from './services/overlapping';
import styles from './episodes-result.module.scss';
import Button from '../../../ui/button';
import history from '../../../technical/history';
import { useAppContext } from '../../provider';
import { EpisodesSearchState } from '../types/EpisodesSearchState';
import useAvailablePacks from './services';

const DEFAULT_RESULT_PAGE_SIZE = 25;

function getEpisodeForCell(pack: Pack, episodeIndex: number) {
  return pack.episodes[episodeIndex];
}

function sortEpisodeForCell(packA: Pack, packB: Pack, episodeIndex: number) {
  const episodeA = getEpisodeForCell(packA, episodeIndex);
  const episodeB = getEpisodeForCell(packB, episodeIndex);
  if (!episodeA && !episodeB) return 0;
  if (!episodeA) return 1;
  if (!episodeB) return -1;
  return sortFunction(
    new Date(episodeA.dateStart),
    new Date(episodeB.dateStart),
  );
}

function formatEpisodeCell(pack: Pack, episodeIndex: number) {
  // @ts-ignore
  const episode: null | Episode = getEpisodeForCell(pack, episodeIndex);

  if (!episode) {
    return '';
  }
  // Show nothing if this episode is null, or if the user is already subscribed to this episode
  const dateStart = new Date(episode.dateStart);
  const dateEnd = new Date(episode.dateEnd);
  const formatter = Intl.DateTimeFormat('fr-FR', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  return `${formatter.format(dateStart)} ${getTime(dateStart)}-${getTime(
    dateEnd,
  )}`;
}

function TableContent({
  user,
  search,
}: {
  user: User;
  search: EpisodesSearchState;
}) {
  const { t } = useTranslation();
  const [availablePacks, loading] = useAvailablePacks(user, search);
  const [selectedPackIds, setSelectedPackIds] = useState<string[]>([]);
  const [registerToEpisodes] = useMutation(
    REGISTER_TO_EPISODES(
      user.type === 'expert' ? 'expertId' : 'firstAnimateurId',
    ),
  );
  const [sendRegisterMail] = useMutation(SEND_REGISTER_EMAIL);
  return (
    <>
      <Table
        locale={{ emptyText: 'Aucun épisode ne correspond à votre recherche' }}
        pagination={{
          defaultPageSize: DEFAULT_RESULT_PAGE_SIZE,
          pageSizeOptions: ['10', '25', '50'],
          showSizeChanger: true,
        }}
        rowClassName={pack => {
          if (!availablePacks) return '';

          return availablePacks
            .filter(availablePack =>
              selectedPackIds.includes(availablePack.key),
            )
            .some(availablePack =>
              arePacksOverlapping(availablePack, pack, user.type),
            )
            ? styles.disabledRow
            : '';
        }}
        loading={loading}
        dataSource={availablePacks}
        columns={[
          {
            title: t('episode.table.educational-establishment'),
            render: (_, pack) => pack.establishment.name,
            sorter: (a, b) =>
              sortFunction(a.establishment.name, b.establishment.name),
          },
          {
            title: t('episode.table.region'),
            render: (_, pack) => pack.establishment.region.name,
            sorter: (a, b) =>
              sortFunction(
                a.establishment.region.name,
                b.establishment.region.name,
              ),
          },
          {
            title: t('episode.table.address'),
            render: (_, pack) =>
              pack.establishment?.addressSecondary &&
              pack.establishment.addressSecondary.trim() ? (
                <span>{pack.establishment.addressSecondary}</span>
              ) : (
                <>
                  <span>{pack.establishment.address}</span>
                  <br />
                  <span>{`${pack.establishment.postalCode} ${pack.establishment.city}`}</span>
                </>
              ),
            sorter: (a, b) =>
              sortFunction(
                a.establishment?.addressSecondary &&
                  a.establishment.addressSecondary.trim()
                  ? a.establishment.addressSecondary
                  : a.establishment.address,
                b.establishment?.addressSecondary &&
                  b.establishment.addressSecondary.trim()
                  ? b.establishment.addressSecondary
                  : b.establishment.address,
              ),
          },
          {
            title: t('episode.table.classroom'),
            dataIndex: 'name',
            sorter: (a, b) => sortFunction(a.name, b.name),
            filters: availablePacks
              ? availablePacks.reduce<ColumnFilterItem[]>((acc, c) => {
                  if (acc.findIndex(elem => elem.value === c.name) === -1)
                    acc.push({
                      text: c.name,
                      value: c.name,
                    });
                  return acc;
                }, [])
              : [],
            onFilter: (value, record) => record.name === value,
          },
          {
            title: t('episode.table.level'),
            render: (_, row) => t(`episode.table.levelChoices.${row.level}`),
            sorter: (a, b) => sortFunction(a.level, b.level),
            filters: availablePacks
              ? availablePacks.reduce<ColumnFilterItem[]>((acc, c) => {
                  if (acc.findIndex(elem => elem.value === c.level) === -1)
                    acc.push({
                      text: t(`episode.table.levelChoices.${c.level}`),
                      value: c.level,
                    });
                  return acc;
                }, [])
              : [],
            onFilter: (value, record) => record.name === value,
          },
          {
            title: t('episode.table.type'),
            render: (_, row) => t(`episode.table.typeChoices.${row.type}`),
            sorter: (a, b) => sortFunction(a.type, b.type),
            filters: importExportTypeClasses.map(value => ({
              text: t(`episode.table.typeChoices.${value}`),
              value,
            })),
            onFilter: (value, record) => record.type === value,
          },
          {
            title: t('episode.table.episode1'),
            render: (_, pack) => formatEpisodeCell(pack, 0),
            sorter: (a, b) => sortEpisodeForCell(a, b, 0),
          },
          {
            title: t('episode.table.episode2'),
            render: (_, pack) => formatEpisodeCell(pack, 1),
            sorter: (a, b) => sortEpisodeForCell(a, b, 1),
          },
          {
            title: t('episode.table.episode3'),
            render: (_, pack) => formatEpisodeCell(pack, 2),
            sorter: (a, b) => sortEpisodeForCell(a, b, 2),
          },
          {
            title: t('episode.table.animators'),
            render: (_, pack) => {
              const animators = pack.episodes
                .filter(e => {
                  if (user.type === 'animator') {
                    return e && !e.animator;
                  }
                  if (user.type === 'expert') {
                    return e && !e.expert;
                  }

                  return false;
                })
                // @ts-ignore episode can't be null here
                .map(e =>
                  e?.animator
                    ? `${e.animator.firstName} ${e.animator.lastName}`
                    : '',
                );

              return (
                <div className={styles.columnWrapper}>
                  {Array.from(new Set(animators)).map(animator => (
                    <span key={animator}>{animator}</span>
                  ))}
                </div>
              );
            },
          },
          {
            title: t('episode.table.experts'),
            render: (_, pack) => {
              const experts = pack.episodes
                .filter(e => {
                  if (!e) {
                    return false;
                  }
                  if (user.type === 'animator') {
                    return e && !e.animator;
                  }
                  if (user.type === 'expert') {
                    return e && !e.expert;
                  }

                  return false;
                })
                // @ts-ignore episode can't be null here
                .map(e =>
                  e?.expert ? `${e.expert.firstName} ${e.expert.lastName}` : '',
                );

              return (
                <div className={styles.columnWrapper}>
                  {Array.from(new Set(experts)).map(expert => (
                    <span key={expert}>{expert}</span>
                  ))}
                </div>
              );
            },
          },
          {
            title: t('episode.table.registration'),
            render: (_, pack) => (
              <Checkbox
                onChange={() => {
                  setSelectedPackIds(
                    selectedPackIds.includes(pack.key)
                      ? selectedPackIds.filter(id => id !== pack.key)
                      : [...selectedPackIds, pack.key],
                  );
                }}
              />
            ),
          },
        ]}
      />
      <Flex column alignItems="flex-end" className={styles.marginTop10}>
        <Button
          type="primary"
          disabled={!selectedPackIds.length}
          onClick={async () => {
            if (!availablePacks) return;

            const episodesToRegister = availablePacks.reduce<string[]>(
              (acc, pack) => {
                if (!selectedPackIds.includes(pack.key)) {
                  return acc;
                }
                return [
                  ...acc,
                  ...pack.episodes
                    .filter(e => new Date(e ? e.dateStart : '') > new Date())
                    .filter(e => {
                      if (e && user.type === 'animator') {
                        return !e.animator;
                      }

                      if (e && user.type === 'expert') {
                        return !e.expert;
                      }
                      return false;
                    })
                    .map(e => e!.id),
                ];
              },
              [],
            );

            await registerToEpisodes({
              variables: {
                userId: user.id,
                episodesIds: episodesToRegister,
              },
            });

            // Envoie du mail de confirmation si le user a coche la case
            if (user.inscriptionNotification) {
              episodesToRegister.forEach(id => {
                sendRegisterMail({
                  variables: {
                    id,
                    recipient: user.email,
                  },
                });
              });
            }
            history.push('/episodes');
          }}
        >
          {t('episode.registration.register-button')}
        </Button>
      </Flex>
    </>
  );
}

export default function EpisodesResult({
  search,
}: {
  search: EpisodesSearchState;
}) {
  const { user } = useAppContext();
  if (!user) {
    return <Table loading />;
  }
  return <TableContent user={user} search={search} />;
}
