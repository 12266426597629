import { ApolloProvider } from '@apollo/client';
import 'antd/dist/antd.css';
import React from 'react';
import ErrorBoundary from 'technical/error-boundary';
import apolloClient from 'technical/graphql/client';
import './App.scss';
import AppBootstrapper from './AppBootstrapper';
import { AppProvider } from './provider';
import Router from './Router';

const App = () => (
  <ErrorBoundary>
    <ApolloProvider client={apolloClient}>
      <AppProvider>
        <AppBootstrapper>
          <Router />
        </AppBootstrapper>
      </AppProvider>
    </ApolloProvider>
  </ErrorBoundary>
);

export default App;
