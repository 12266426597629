import { Button } from 'antd';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button';
import classnames from 'classnames';
import React from 'react';
import history from 'technical/history';

interface ButtonProps extends AntdButtonProps {
  className?: string;
  path?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

interface Props extends ButtonProps {}
function MenuLink({ children, className, path, onClick, ...props }: Props) {
  const isExternalLink = path && path[0] !== '/';
  function handleOnClick() {
    if (path) {
      if (!isExternalLink) {
        history.push(path);
      }
    }
  }

  return (
    <Button
      type="link"
      onClick={onClick || handleOnClick}
      href={isExternalLink ? path : undefined}
      target="_blank"
      className={classnames(className, 'menuLink')}
      {...props}
    >
      {children}
    </Button>
  );
}

export default MenuLink;
