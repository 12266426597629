import { Result } from 'antd';
import React from 'react';
import errorReporting from 'technical/error-reporting';
import history from 'technical/history';
import logger from 'technical/logger';
import Button from 'ui/button';

interface Props {}
interface State {
  hasError: false;
  error?: Error;
}

class ErrorBoundaryComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error) {
    logger.error(error);
    errorReporting.warning(error);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <Result
          status="warning"
          title="There are some problems with your operation."
          extra={
            <Button type="primary" onClick={() => history.push('/')}>
              Retourner à l&asp;accueil
            </Button>
          }
        />
      );
    }

    return children;
  }
}

const ErrorBoundary = ErrorBoundaryComponent;

export default ErrorBoundary;
