import { User } from 'business/user/types';
import {
  roleMappingInverse,
  chosenPathByEpisode,
  infoEpisodeApi,
  listIntervenant,
  roleMapping,
  roleOnEpisode,
  speakerEval,
} from '../api/type';
/* eslint-disable import/prefer-default-export */
export const getRoleOnEpisode = (user: User, listAnimator: listIntervenant) => {
  const roleArray = Object.keys(listAnimator).find((key: string) => {
    const safeKey = key as keyof listIntervenant;
    return listAnimator[safeKey] === user.id;
  });

  return roleArray ? roleMappingInverse(roleArray) : undefined;
};

const getPathChosenForAnimAndEpisode = (
  speakerEvaluation: speakerEval[],
  episodeNumber: number,
  listAnimator: listIntervenant,
  role: roleOnEpisode,
) => {
  return speakerEvaluation
    .filter(evalFirstAnim => {
      const roleId = roleMapping(role);
      return (
        roleId &&
        evalFirstAnim.episode.number === episodeNumber &&
        evalFirstAnim.intervenant_id === listAnimator[roleId]
      );
    })
    .map(chosenEval => {
      return chosenEval.pathChosen;
    })[0];
};

export const mapEvalPaths: chosenPathByEpisode | any = (
  info: infoEpisodeApi,
) => {
  const pathInfoByEpAndAnim: chosenPathByEpisode | any = {};
  if (info) {
    info.class[0]?.episodes.forEach((episode: listIntervenant) => {
      const epNumber = episode.number;

      const speakerEvaluation = info?.speakerEvaluation;
      pathInfoByEpAndAnim[epNumber] = {
        firstAnimator: getPathChosenForAnimAndEpisode(
          speakerEvaluation,
          epNumber,
          episode,
          roleOnEpisode.firstAnimator,
        ),
        secondAnimator: getPathChosenForAnimAndEpisode(
          speakerEvaluation,
          epNumber,
          episode,
          roleOnEpisode.secondAnimator,
        ),
        expert: getPathChosenForAnimAndEpisode(
          speakerEvaluation,
          epNumber,
          episode,
          roleOnEpisode.expert,
        ),
      };
    });
  }
  return pathInfoByEpAndAnim;
};

export function shouldUpdateClass(
  pathMapped: any,
  episodeNumber: number,
  role: roleOnEpisode | undefined,
) {
  const laterEpisodeEvalList = pathMapped[episodeNumber + 1];
  const currentEpisodeEvalList = pathMapped[episodeNumber];

  if (episodeNumber === 3) {
    return false;
  }
  if (
    !laterEpisodeEvalList ||
    (!laterEpisodeEvalList.expert &&
      !laterEpisodeEvalList.firstAnimator &&
      !laterEpisodeEvalList.secondAnimator)
  ) {
    if (role === roleOnEpisode.expert) {
      return true;
    }
    if (
      role === roleOnEpisode.firstAnimator &&
      !currentEpisodeEvalList.expert
    ) {
      return true;
    }
    if (
      role === roleOnEpisode.secondAnimator &&
      !currentEpisodeEvalList.expert &&
      !currentEpisodeEvalList.firstAnimator
    ) {
      return true;
    }
  }
  return false;
}
