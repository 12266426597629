import validate from 'validate.js';

// The declaration file does not support this field
(validate as any).options = {
  fullMessages: false,
};

export const REQUIRED = {
  presence: { message: 'required', allowEmpty: false },
};

export const ALPHABETICAL = {
  format: {
    message: 'no_special_chars',
    pattern: /^[^±!@£$%^&*+§¡€#¢§¶•ªº«\\/<>?:;|=€©\d]{0,250}$/,
    flags: 'i',
  },
};

export const NUMERIC = {
  numericality: {
    onlyInteger: true,
    notValid: 'wrong_number_format',
    notInteger: 'wrong_number_format',
    notGreaterThanOrEqualTo: 'not_gte',
    notLessThanOrEqualTo: 'not_lte',
  },
};

export const INTEGER = {
  numericality: {
    onlyInteger: true,
    notValid: 'wrong_number_format',
    notInteger: 'wrong_number_format',
    notGreaterThanOrEqualTo: 'not_gte',
    notLessThanOrEqualTo: 'not_lte',
    noStrings: true,
  },
};

export const NUMBER = {
  numericality: {
    onlyInteger: false,
    notValid: 'wrong_number_format',
    notGreaterThanOrEqualTo: 'not_gte',
    notLessThanOrEqualTo: 'not_lte',
    noStrings: true,
  },
};

export const PHONE_NUMBER = {
  format: {
    message: 'wrong_phone_format',
    pattern: /^\d{10}$/,
  },
};

export const SOCIAL_SECURITY_NUMBER = {
  format: {
    message: 'wrong_social_security_format',
    pattern: /^(1|2)[0-9]{12}$/,
  },
};

export const BOOLEAN = {
  inclusion: {
    within: [true, false],
    message: 'wrong_boolean_format',
  },
};

export const INTEGER_RANGE = (min: number, max: number) => ({
  numericality: {
    ...NUMERIC.numericality,
    greaterThanOrEqualTo: min,
    lessThanOrEqualTo: max,
  },
});

export const LENGTH_RANGE = (min: number, max: number) => ({
  length: {
    minimum: min,
    maximum: max,
    tooShort: 'too_short',
    tooLong: 'too_long',
    getVariables: () => ({
      minimum: min,
      maximum: max,
    }),
  },
});

export const EMAIL = {
  email: { message: 'wrong_email_address' },
};

export const ENUM = (enumObject: any) => ({
  inclusion: { within: Object.values(enumObject), message: 'not_in_enum' },
});

/**
 * Validates if password has 8 characeters, 1 CAP, 1 Lower case and 1 number
 */
validate.validators.password = (
  password: string,
  options: { [key: string]: string },
): string | null => {
  const passwordValidator = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
  );
  if (!passwordValidator.test(password)) {
    return options.message;
  }
  return null;
};

/**
 * Defines the ISPASSWORD validation rule, passes a { message } options object
 * to the validator password
 */
export const ISPASSWORD = (message: string) => ({
  password: { message },
});

/**
 * Defines the EQUALITY validation rule
 */
export const EQUALITY = (key: string) => ({
  equality: {
    attribute: key,
    message: 'not_equal',
  },
});
