import { useAppContext } from 'business/provider';
import React, { ReactElement } from 'react';
import { Redirect, useRouteMatch } from 'react-router';
import { login } from './user/services/authentication';
import NotPermittedUser from './user/pages/notPermittedUser';

const DEFAULT_CONNECTED_ROUTE = '/';

/**
 * Redirect the user to the default not-connected route when the user
 * is not-connected and intend to access connected route
 */
export function ConnectedContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  const { isConnected, user } = useAppContext();
  const match = useRouteMatch('/sign-up');
  const adminRoles = [
    'operator',
    'administrator',
    'region-manager',
    'national',
  ];

  if (!isConnected) {
    login();
  } else if (!user?.isActive) {
    return (
      <NotPermittedUser title="user.inactive.title" text="user.inactive.text" />
    );
  } else if (adminRoles.includes(user.type)) {
    return <NotPermittedUser title="user.admin.title" text="user.admin.text" />;
  }
  // if the user is not registered and if the route is not signup page go to signup page
  else if (!user?.isRegistered && !match?.isExact) {
    return <Redirect to="/sign-up" />;
  }
  return children as ReactElement;
}

/**
 * Redirect the user to the default connected route when the user is
 * connected and intend to access not connected route
 */
export function NotConnectedContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  const { isConnected } = useAppContext();

  return isConnected ? (
    <Redirect to={DEFAULT_CONNECTED_ROUTE} />
  ) : (
    (children as ReactElement)
  );
}

export function ConnectedHoc<Props>(
  Component: React.ComponentType<Props>,
): React.ComponentType<Props> {
  return ({ ...props }) => {
    return (
      <ConnectedContainer>
        <Component {...props} />
      </ConnectedContainer>
    );
  };
}

export function NotConnectedHoc<Props>(
  Component: React.ComponentType<Props>,
): React.ComponentType<Props> {
  return ({ ...props }) => {
    return (
      <NotConnectedContainer>
        <Component {...props} />
      </NotConnectedContainer>
    );
  };
}
