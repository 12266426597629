import React from 'react';
import Page from './page';

interface Props {
  header?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
}

function DisconnectedPage(props: Props) {
  return <Page {...props} isConnected={false} />;
}

export default DisconnectedPage;
