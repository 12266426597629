import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback, useEffect, useState } from 'react';
import { setUserId } from 'technical/analytics';
import errorReporting from 'technical/error-reporting';
import {
  getAuthResult,
  isAuthenticated,
  isAuthInitialized,
} from '../services/authentication';
import { isUserTrained } from '../services/training';
import { HasuraUser, User } from '../types';

// TODO: or type === new ?
const CommonUserFieldsFragment = gql`
  fragment CommonUserFields on user {
    id
    lastName
    firstName
    email
    isActive
    regionId
    type
    civility
    user_region_animations {
      region_id
      region {
        name
      }
    }
    oldRegionId
    changeRegionDate
    phone
    authId
    user_trainings(where: { validationDate: { _is_null: false } }) {
      training_id
      validationDate
      training {
        dateStart
        dateEnd
        type
      }
    }
    user_trainings_not_validated: user_trainings(
      where: { validationDate: { _is_null: true } }
    ) {
      training_id
    }
    regionId
    origin
    siteId
    newPackNotification
    inscriptionNotification
  }
`;

const QUERY_MY_USER = gql`
  query QueryMyUser($email: String) {
    user(where: { email: { _eq: $email } }) {
      ...CommonUserFields
    }
  }
  ${CommonUserFieldsFragment}
`;

const MUTATION_CREATE_MY_USER = gql`
  mutation CreateMyUser(
    $id: String
    $email: String
    $firstName: String
    $lastName: String
  ) {
    insert_user(
      objects: [
        {
          authId: $id
          email: $email
          firstName: $firstName
          lastName: $lastName
          isActive: true
        }
      ]
    ) {
      returning {
        ...CommonUserFields
      }
    }
  }
  ${CommonUserFieldsFragment}
`;

const MUTATION_UPDATE_AUTH_ID = gql`
  mutation UpdateAuthId($id: uuid, $authId: String) {
    update_user(where: { id: { _eq: $id } }, _set: { authId: $authId }) {
      returning {
        ...CommonUserFields
      }
    }
  }
  ${CommonUserFieldsFragment}
`;

function formatTrainedUser(user: HasuraUser | undefined): User | undefined {
  if (!user) {
    return undefined;
  }

  const isTrained = isUserTrained(user);
  const regionsAnimation = user.user_region_animations.map(r => {
    return { id: r.region_id, name: r.region.name };
  });

  return {
    id: user.id,
    email: user.email,
    lastName: user.lastName,
    firstName: user.firstName,
    type: user.type,
    isActive: user.isActive,
    regionId: user.regionId,
    regionsAnimation,
    civility: user.civility,
    phone: user.phone,
    origin: user.origin,
    newPackNotification: user.newPackNotification,
    inscriptionNotification: user.inscriptionNotification,
    isTrained,
    isRegistered: !!user.regionId,
    hasTrainingsNotValidated: user.user_trainings_not_validated.length > 0,
    siteId: user.siteId,
    oldRegionId: user.oldRegionId,
    changeRegionDate: user.changeRegionDate,
  };
}

function useUserData() {
  const [isBootstraped, setIsBootstraped] = useState(false);
  const [user, setUser] = useState<HasuraUser | undefined>(undefined);
  const client = useApolloClient();

  // @todo use subscription for user data when below error is handled (cf this commit to "revert" code)
  // BEWARE: on invalid JWT, subscriptions are automatically disconnected and not re-subscribed
  // Error: cannot start as connection_init failed with : Malformed Authorization header

  /**
   * Fonction to be called if needed typcially when authentication status changes (ie - was not connected and now am connected)
   */
  const requestRebootstrap = useCallback(
    function requestRebootstrap() {
      return isAuthInitialized
        .then(async () => {
          if (!isAuthenticated()) {
            return;
          }

          const authResult = getAuthResult();
          if (!authResult) {
            throw new Error('missing authResult to create new User');
          }
          const { email, sub } = authResult.idTokenPayload;

          // try to get my user
          let {
            data: {
              user: [finalUser],
            },
          } = await client.query({
            query: QUERY_MY_USER,
            variables: { email },
            fetchPolicy: 'network-only',
          });
          // if my user doesn't exist - create it!
          if (!finalUser) {
            const { data } = await client.mutate({
              mutation: MUTATION_CREATE_MY_USER,
              variables: {
                id: sub,
                email,
              },
            });
            if (!data) {
              throw new Error('no data after user creation');
            }

            const {
              insert_user: {
                returning: [freshlyCreatedMuyUser],
              },
            } = data;
            finalUser = freshlyCreatedMuyUser;
          } else if (finalUser.authId !== sub) {
            const { data } = await client.mutate({
              mutation: MUTATION_UPDATE_AUTH_ID,
              variables: {
                id: finalUser.id,
                authId: sub,
              },
            });
            if (!data) {
              throw new Error('no data after user update');
            }

            const {
              update_user: {
                returning: [freshlyUpdatedMuyUser],
              },
            } = data;
            finalUser = freshlyUpdatedMuyUser;
          }
          setUser({
            ...finalUser,
          });
        })
        .catch(err => {
          errorReporting.warning(err);
        })
        .finally(() => setIsBootstraped(true));
    },
    [client],
  );

  useEffect(() => {
    requestRebootstrap();
  }, [client, requestRebootstrap]);

  useEffect(() => {
    setUserId(user?.id || undefined);
    if (user) {
      errorReporting.setUser({
        id: user.id,
        email: user.email,
      });
    } else {
      errorReporting.removeUser();
    }
  }, [user]);

  return {
    user: formatTrainedUser(user),
    isConnected: !!user,
    isBootstraped,
    requestRebootstrap,
  };
}

export default useUserData;
