import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import moment, { Moment } from 'moment';
import momentTimezone from 'moment-timezone';
import { User } from 'business/user/types';
import {
  Episode,
  GET_AVAILABLE_PACKS,
  GET_MY_PACKS,
  Pack,
  PackData,
} from '../../api';
import { EpisodesSearchState } from '../../types/EpisodesSearchState';
import { arePacksOverlapping } from './overlapping';

const MORNING_MAX_HOUR = 13; // timezone "Europe/Paris"
const NOW = new Date();

const sanitizeSearchState = (searchState: EpisodesSearchState) => ({
  variables: {
    dateStart: searchState.dateStart ?? NOW,
    regionId:
      (searchState.searchType === 'region' ||
        searchState.regionSearchType === 'address' ||
        searchState.regionSearchType === 'establishment') &&
      searchState.regionId
        ? searchState.regionId
        : undefined,
    establishmentId:
      (searchState.searchType === 'establishment' ||
        searchState.regionSearchType === 'establishment') &&
      searchState.establishment
        ? searchState.establishment
        : undefined,
    location:
      (searchState.searchType === 'address' ||
        searchState.regionSearchType === 'address') &&
      searchState.location &&
      searchState.distance !== 'null'
        ? {
            type: 'Point',
            // We use lonlat instead of latlon because the Production DB has reversed latitude and longitude
            coordinates: [searchState.location.lon, searchState.location.lat],
          }
        : undefined,
    distance:
      (searchState.searchType === 'address' ||
        searchState.regionSearchType === 'address') &&
      searchState.location &&
      searchState.distance !== 'null'
        ? searchState.distance
        : undefined,
  },
});

export const filterPackDateStart = (
  packBeforeFilter: Pack[],
  dateStart: Moment,
): Pack[] => {
  const packAfterFilter = packBeforeFilter.map(pack => ({
    ...pack,
    episodes: pack.episodes.map(episode =>
      episode && moment(episode.dateStart) > dateStart ? episode : null,
    ),
  }));
  return packAfterFilter;
};

const isInPreferredTime = (
  dateStart: string,
  preferredTime: 'morning' | 'afternoon',
): boolean => {
  const hourStart = momentTimezone.tz(dateStart, 'Europe/Paris').hour();
  return preferredTime === 'morning'
    ? hourStart < MORNING_MAX_HOUR
    : hourStart >= MORNING_MAX_HOUR;
};

const filterPackPeriod = (
  packBeforeFilter: Pack[],
  preferredTime: 'morning' | 'afternoon',
): Pack[] => {
  const packAfterFilter = packBeforeFilter.map(pack => ({
    ...pack,
    episodes: pack.episodes.map(episode =>
      episode && isInPreferredTime(episode.dateStart, preferredTime)
        ? episode
        : null,
    ),
  }));
  return packAfterFilter;
};

const isThisSlotTaken = (episode: Episode, user: User): boolean => {
  return (
    (user.type === 'expert' && !!episode.expert?.id) ||
    (user.type === 'animator' && !!episode.animator?.id)
  );
};

const amIAlreadySubscribedToThisEpisode = (
  episode: Episode,
  user: User,
): boolean => {
  return (
    user.id === episode.expert?.id ||
    user.id === episode.animator?.id ||
    user.id === episode.animator_2?.id
  );
};

export const filterPackAlreadySubscribed = (
  packBeforeFilter: Pack[],
  user: User,
): Pack[] => {
  const packAfterFilter = packBeforeFilter.map(pack => ({
    ...pack,
    episodes: pack.episodes.map(episode =>
      !episode ||
      isThisSlotTaken(episode, user) ||
      amIAlreadySubscribedToThisEpisode(episode, user)
        ? null
        : episode,
    ),
  }));

  return packAfterFilter;
};

export const filterPackOverlapping = (
  packBeforeFilter: Pack[],
  myPacks: Pack[],
  user: User,
): Pack[] => {
  const packAfterFilter = packBeforeFilter.filter(
    availablePack =>
      !myPacks.some(myPack =>
        arePacksOverlapping(availablePack, myPack, user.type),
      ),
  );
  return packAfterFilter;
};

const sortPackbyDateStart = (unsortedPacks: Pack[]): Pack[] => {
  const establishmentIds = unsortedPacks
    .map(p => p.establishment.id)
    // unique id
    .filter((value, index, self) => self.indexOf(value) === index);

  const sortedPacks = establishmentIds
    .map(id =>
      unsortedPacks
        .filter(p => p.establishment.id === id)
        .filter(p => p.episodes.length > 0)
        .sort(
          (a, b) =>
            new Date(a.episodes[0] ? a.episodes[0].dateStart : '').getTime() -
            new Date(b.episodes[0] ? b.episodes[0].dateStart : '').getTime(),
        ),
    )
    .flat();
  // SortedPacks is filtered Establishment by Establishment, Date by Date
  return sortedPacks;
};

const filterPackWithNoEpisodes = (packBeforeFilter: Pack[]): Pack[] => {
  const packAfterFilter = packBeforeFilter.filter(pack => {
    let isThereAnEpisode = false;
    pack.episodes.forEach(episode => {
      if (episode !== null) {
        isThereAnEpisode = true;
      }
    });
    return isThereAnEpisode;
  });

  return packAfterFilter;
};

export default function useAvailablePacks(
  user: User,
  searchState: EpisodesSearchState,
): [Pack[], boolean] {
  const [availablePacks, setAvailablePacks] = useState<Pack[]>([]);
  const [myPacks, setMyPacks] = useState<Pack[]>([]);
  const [isAvailablePacksLoading, setIsAvailablePacksLoading] = useState(false);
  const [isMyPacksLoading, setIsMyPacksLoading] = useState(false);

  // Query GQL retrieving available packs
  const {
    data: availablePacksResult,
    loading: isAvailablePacksResultLoading,
  } = useQuery<PackData>(
    GET_AVAILABLE_PACKS({ userType: user.type, ...searchState }),
    { ...sanitizeSearchState(searchState) },
  );

  // Query GQL retrieving user's pack
  const { data: myPacksResult, loading: isMyPackResultLoading } = useQuery<
    PackData
  >(GET_MY_PACKS, { variables: { userId: user.id } });

  // Set My Packs
  useEffect(() => {
    if (myPacksResult) {
      setMyPacks(myPacksResult.class);
      setIsMyPacksLoading(isMyPackResultLoading);
    }
  }, [myPacksResult, isMyPackResultLoading]);

  // Set Available Packs
  useEffect(() => {
    if (availablePacksResult) {
      const allPacks = availablePacksResult.class;
      const dateStart = moment(searchState.dateStart ?? NOW);
      const packsFilteredDateStart = filterPackDateStart(allPacks, dateStart);
      const packsFilteredPeriod = searchState.preferredTime
        ? filterPackPeriod(packsFilteredDateStart, searchState.preferredTime)
        : packsFilteredDateStart;
      const packsFilteredAlreadySubscribed = filterPackAlreadySubscribed(
        packsFilteredPeriod,
        user,
      );
      const packsFilteredOverlapping = filterPackOverlapping(
        packsFilteredAlreadySubscribed,
        myPacks,
        user,
      );
      const packsStillHavingEpisodes = filterPackWithNoEpisodes(
        packsFilteredOverlapping,
      );
      const packsSorted = sortPackbyDateStart(packsStillHavingEpisodes);
      setAvailablePacks(packsSorted);
      setIsAvailablePacksLoading(isAvailablePacksResultLoading);
    }
  }, [
    availablePacksResult,
    isAvailablePacksResultLoading,
    searchState,
    myPacks,
    user,
  ]);

  return [availablePacks, isAvailablePacksLoading || isMyPacksLoading];
}
