import { useAppContext } from 'business/provider';
import React from 'react';
import Page from './page';

interface Props {
  header?: React.ReactNode;
  children?: React.ReactNode;
  img?: { src: string; alt: string };
  footer?: React.ReactNode;
}

function ConnectedPage(props: Props) {
  const { isConnected } = useAppContext();

  return <Page {...props} isConnected={isConnected} />;
}

export default ConnectedPage;
