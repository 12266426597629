import React from 'react';
import { Redirect } from 'react-router';
import { useAppContext } from 'business/provider';

const Home = () => {
  const { user } = useAppContext();
  if (!user) {
    return null;
  }

  if (['animator', 'expert'].includes(user.type)) {
    return <Redirect to="/episodes" />;
  }

  return (
    <Redirect
      to={
        user.hasTrainingsNotValidated
          ? '/new-trainings'
          : '/new-training-registration'
      }
    />
  );
};

export default Home;
