import { MailOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import { Auth0ParseHashError } from 'auth0-js';
import DisconnectedPage from 'business/layout/disconnectedPage';
import { useAppContext } from 'business/provider';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import logger from 'technical/logger';
import Button from 'ui/button';
import Loader from 'ui/loader';
import { login, requestLoginCallback } from '../services/authentication';

function LoginProviderCallbackScreen() {
  const [error, setError] = React.useState<Auth0ParseHashError | undefined>(
    undefined,
  );
  const { t } = useTranslation();
  const { requestRebootstrap } = useAppContext();

  React.useEffect(() => {
    requestLoginCallback()
      .then(() => {
        requestRebootstrap();
      })
      .catch(err => {
        logger.error(err);
        setError(err);
      });
  }, [requestRebootstrap]);

  if (error) {
    let errorKey = 'default';

    switch (error.errorDescription) {
      case 'invalid-email-domain':
      case 'email-not-verified':
        errorKey = error.errorDescription;
        break;
      default:
        break;
    }

    return (
      <DisconnectedPage>
        <Result
          icon={<MailOutlined />}
          title={t(`notConnected.login.callback.error.${errorKey}.title`)}
          subTitle={t(`notConnected.login.callback.error.${errorKey}.subTitle`)}
          extra={
            errorKey === 'email-not-verified' ? (
              undefined
            ) : (
              <Button type="primary" key="console" onClick={login}>
                {t(`notConnected.login.callback.error.${errorKey}.button`)}
              </Button>
            )
          }
        />
      </DisconnectedPage>
    );
  }
  return (
    // Shortly displaying loading indicator, before either displaying error or redirecting to home page
    <DisconnectedPage>
      <Loader />
    </DisconnectedPage>
  );
}

export default LoginProviderCallbackScreen;
