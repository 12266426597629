import React, {
  createContext,
  PropsWithChildren,
  Reducer,
  useContext,
  useReducer,
} from 'react';
import { ColumnType } from 'antd/lib/table/interface';

interface SavedTable {
  filters: Record<string, string[]>;
  sort: null | {
    column: string;
    status: SortStatus;
  };
}
type TableState = Record<string, SavedTable>;

type SortStatus = 'ascend' | 'descend' | null | undefined;

interface TableAction {
  type: 'update';
  payload: TableActionPayload;
}

interface TableActionPayload {
  idTable: string;
  sort: SortStatus;
  filters: Record<string, string[]>;
  key: string;
}

interface TableContextType {
  update: (payload: TableActionPayload) => void;
  getSort: (tableId: string, columnKey: string) => SortStatus;
  getFilteredValues: (tableId: string, columnKey: string) => string[] | null;
}

const getInitialState = (): TableState => {
  const filters = localStorage.getItem('filters');
  return filters ? JSON.parse(filters) : {};
};

const TableContext = createContext<TableContextType>({
  update: () => null,
  getSort: () => null,
  getFilteredValues: () => null,
});

const reducer: Reducer<TableState, TableAction> = (previousState, action) => {
  switch (action.type) {
    case 'update': {
      let table: SavedTable = previousState[action.payload.idTable] ?? {
        filters: {},
        sort: null,
      };
      let sort = null;
      if (
        action.payload.key &&
        action.payload.sort !== null &&
        action.payload.sort !== undefined
      ) {
        sort = {
          status: action.payload.sort,
          column: action.payload.key,
        };
      }
      table = {
        ...table,
        sort,
        filters: action.payload.filters,
      };
      const newFilters = {
        ...previousState,
        [action.payload.idTable]: table,
      };
      localStorage.setItem('filters', JSON.stringify(newFilters));
      return newFilters;
    }
    default: {
      const exhaustiveCheck: never = action.type;
      throw new Error(exhaustiveCheck);
    }
  }
};

const TableContextProvider = (props: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer<typeof reducer>(
    reducer,
    getInitialState(),
  );
  const { children } = props;
  const update = (payload: TableActionPayload): void => {
    dispatch({ type: 'update', payload });
  };
  const getSort = (tableId: string, columnKey: string): SortStatus => {
    const tableState = state[tableId];
    if (!tableState || !state[tableId].sort || tableState.sort === null) {
      return null;
    }
    if (tableState.sort.column !== columnKey) {
      return null;
    }
    return tableState.sort.status;
  };
  const getFilteredValues = (
    tableId: string,
    columnKey: string,
  ): string[] | null => {
    const tableState = state[tableId];
    if (!tableState || !tableState.filters[columnKey]) {
      return null;
    }
    return tableState.filters[columnKey];
  };

  return (
    <TableContext.Provider value={{ update, getSort, getFilteredValues }}>
      {children}
    </TableContext.Provider>
  );
};

export const useTableContext = (): TableContextType => {
  const context = useContext(TableContext);
  if (!context) {
    throw new Error('No TableContextProvider found');
  }
  return context;
};

export function useMemoryColumn(
  idTable: string,
  columnKey: string,
): ColumnType<any> {
  const { getSort, getFilteredValues } = useTableContext();
  return {
    key: columnKey,
    defaultSortOrder: getSort(idTable, columnKey),
    defaultFilteredValue: getFilteredValues(idTable, columnKey),
  };
}

export default TableContextProvider;
