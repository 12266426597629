import gql from 'graphql-tag';
import { EpisodesSearchState } from '../types/EpisodesSearchState';

export const GET_AVAILABLE_PACKS = (
  option: EpisodesSearchState & { userType: string },
) => {
  const params = ['$dateStart: timestamptz'];
  if (
    (option.searchType === 'establishment' ||
      option.regionSearchType === 'establishment') &&
    option.establishment
  ) {
    params.push('$establishmentId: uuid');
  }
  if (option.searchType === 'city' && option.city) {
    params.push('$city: String');
  }
  if (option.searchType === 'postalCode' && option.postalCode) {
    params.push('$postalCode: String');
  }
  if (
    (option.searchType === 'region' ||
      option.regionSearchType === 'address' ||
      option.regionSearchType === 'establishment') &&
    option.regionId
  ) {
    params.push('$regionId: uuid');
  }
  if (
    (option.searchType === 'address' ||
      option.regionSearchType === 'address') &&
    option.location &&
    option.distance !== 'null'
  ) {
    params.push('$location: geography!', '$distance: Float!');
  }

  const establishmentCondition = `
    ${
      option.searchType === 'establishment' && option.establishment
        ? 'id: { _eq: $establishmentId }'
        : ''
    }
    ${
      option.searchType === 'city' && option.city
        ? 'city: { _ilike: $city }'
        : ''
    }
    ${
      option.searchType === 'postalCode' && option.postalCode
        ? 'postalCode: { _like: $postalCode }'
        : ''
    }
    ${
      option.searchType === 'region' && option.regionId
        ? `
        region: {
          id: { _eq: $regionId }
        }`
        : ''
    }
    ${
      option.searchType === 'address' &&
      option.location &&
      option.distance !== 'null'
        ? `
            location: {
              _st_d_within: {
                distance: $distance,
                from: $location
              }
            }
          `
        : ''
    }
    ${
      option.regionSearchType === 'address' &&
      option.regionId &&
      option.location &&
      option.distance !== 'null'
        ? `
            location: {
              _st_d_within: {
                distance: $distance,
                from: $location
              }
            }
          `
        : ''
    }
    ${
      option.regionSearchType === 'establishment' &&
      option.regionId &&
      option.establishment
        ? `
            id: { _eq: $establishmentId }
          `
        : ''
    }
  `;

  return gql`
    query(${params.join(',')}) {
      class(
        order_by: {establishment: {name: asc}}
        where: {
          episodes: {
            dateStart: { _gt: $dateStart },
            ${
              option.userType === 'expert' ? 'expertId' : 'firstAnimateurId'
            }: { _is_null: true }
          },
          establishment: {
            ${establishmentCondition}
          },
          publicationDate: {
            _is_null : false    
          }
        }
      ) {
        key: id
        name
        type
        level    
        establishment {
          region {
            name
            id
          }
          id
          name
          address
        addressSecondary
          postalCode
        city
      }
      episodes(order_by: { dateStart: asc }) {
        id
        dateStart
        dateEnd
        animator: animateur_1 {
          id
          firstName
          lastName
        }
        expert {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
};

export const GET_MY_PACKS = gql`
  query($userId: uuid, $dateStart: timestamptz, $dateEnd: timestamptz) {
    class(
      where: {
        episodes: {
          dateStart: { _gt: $dateStart }
          dateEnd: { _lt: $dateEnd }
          _or: [
            { firstAnimateurId: { _eq: $userId } }
            { secondAnimateurId: { _eq: $userId } }
            { expertId: { _eq: $userId } }
          ]
        }
      }
    ) {
      key: id
      name
      type
      level
      establishment {
        id
        name
        address
        addressSecondary
        postalCode
        city
        region {
          name
          user {
            email
            firstName
            lastName
            phone
          }
        }
      }
      episodes(
        order_by: { dateStart: asc }
        where: {
          _or: [
            { firstAnimateurId: { _eq: $userId } }
            { secondAnimateurId: { _eq: $userId } }
            { expertId: { _eq: $userId } }
          ]
        }
      ) {
        id
        number
        dateStart
        dateEnd
        classroomNumber
        teacher {
          firstName
          lastName
          civility
        }
        animator: animateur_1 {
          id
          firstName
          lastName
          email
          phone
        }
        animator_2: animateur_2 {
          id
          firstName
          lastName
          email
          phone
        }
        expert {
          id
          firstName
          lastName
          email
          phone
        }
      }
    }
  }
`;

export const REGISTER_TO_EPISODES = (columnName: string) => gql`
  mutation($userId: uuid, $episodesIds: [uuid!]) {
    update_episode(
      where: { id: { _in: $episodesIds } }
      _set: { ${columnName}: $userId }
    ) {
      affected_rows
    }
  }
`;

export const UNREGISTER_TO_EPISODE = (columnName: string) => gql`
  mutation($episodeId: uuid!) {
    update_episode(
      where: { id: { _eq: $episodeId } }
      _set: { ${columnName}: null }
    ) {
      affected_rows
    }
  }
`;

export const SEND_UNREGISTER_EMAIL = gql`
  mutation(
    $id: String!
    $recipient: String!
    $episodeNumber: Int!
    $className: String!
    $establishmentName: String!
    $episodeDate: String!
    $regionManager: String!
  ) {
    episodeUnregisterEmail(
      input: {
        id: $id
        recipient: $recipient
        episodeNumber: $episodeNumber
        className: $className
        establishmentName: $establishmentName
        episodeDate: $episodeDate
        regionManager: $regionManager
      }
    ) {
      success
    }
  }
`;

export const SEND_REGISTER_EMAIL = gql`
  mutation($id: String!, $recipient: String!) {
    episodeRegisterEmail(input: { id: $id, recipient: $recipient }) {
      success
    }
  }
`;

export const GET_ESTABLISHMENTS = gql`
  query {
    establishment {
      id
      name
      address
      addressSecondary
      postalCode
      city
      region {
        id
        name
      }
    }
  }
`;

export const importExportTypeClasses = [
  'classical',
  'segpa',
  'job_prepa',
  'second_pro',
  'cap',
  'ulis',
  'upe2a',
  'cap_orientation',
];

export type Episode = {
  id: string;
  number: number;
  dateStart: string;
  dateEnd: string;
  classroomNumber: string | null;
  teacher: null | {
    firstName: string;
    lastName: string;
    civility: string;
  };
  animator: null | {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  animator_2: null | {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  expert: null | {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
};

export type Establishment = {
  id: string;
  name: string;
  address: string;
  addressSecondary?: string;
  postalCode: string;
  city: string;
  region: {
    id : string;
    name: string;
    user: {
      email: string | null;
      firstName: string | null;
      lastName: string | null;
      phone: string | null;
    };
  };
};

export type Pack = {
  [x: string]: any;
  key: string;
  name: string;
  type: string;
  level: string;
  establishment: Establishment;
  episodes: (Episode | null)[];
};

export type PackData = {
  class: Pack[];
};
