import { HasuraUser, User } from '../types';

function isBeforeYearBeginning(now: Date) {
  // Change date
  return now.getMonth() < 6 || (now.getMonth() === 6 && now.getDate() < 6);
}

export function getCurrentScholarYear() {
  const now = new Date(Date.now());
  const year = now.getFullYear();

  // Change date

  if (isBeforeYearBeginning(now)) {
    return [new Date(`${year - 1}-07-05`), new Date(`${year}-07-05`)];
  }
  // Change date

  return [new Date(`${year}-07-05`), new Date(`${year + 1}-07-05`)];
}

// Retourne vrai si un user a passe une formation habilitante durant l annee scolaire
export function isUserTrained(user: HasuraUser) {
  const [firstDate, lastDate] = getCurrentScholarYear();
  return user.user_trainings.some(({ training, validationDate }) => {
    const trainingTime = new Date(validationDate).getTime();
    return (
      trainingTime >= firstDate.getTime() &&
      trainingTime < lastDate.getTime() &&
      (training.type === 'renewal' ||
        training.type === 'expert_renewal' ||
        training.type === 'new')
    );
  });
}

export function userTypeToTrainingType(user: User): Array<string> {
  const trainingTypes = [];
  if (user.type === 'new') {
    trainingTypes.push('new');
    return trainingTypes;
  }

  if (!user.isTrained) {
    if (user.type === 'animator') {
      trainingTypes.push('renewal');
    }
    if (user.type === 'expert') {
      trainingTypes.push('renewal');
      trainingTypes.push('expert_renewal');
    }
  }

  if (user.type === 'animator') {
    trainingTypes.push('expert');
  }
  trainingTypes.push('other');
  return trainingTypes;
}
