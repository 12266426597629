import { Typography } from 'antd';
import React from 'react';
import ConnectedPage from 'business/layout/connectedPage';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import Title from '../../../ui/title';
import Spacer from '../../../ui/spacer';
import UserForm from '../../../ui/form/userForm';

function UserProfilePage() {
  const { t } = useTranslation();

  return (
    <ConnectedPage>
      <div className={styles.wrapper}>
        <Title title={t('user.edit-profile.title')} />
        <Spacer />
        <Typography.Text>{t('user.edit-profile.text')}</Typography.Text>
        <Spacer />
        <div className={styles.formWrapper}>
          <UserForm
            disableRegion
            submitText={t('user.edit-profile.form.submit')}
          />
        </div>
      </div>
    </ConnectedPage>
  );
}

export default UserProfilePage;
