export interface chosenPathEvaluationApi {
  speakerEvaluation: speakerEval[];
}
export interface speakerEval {
  pathChosen: theme;
  episode: {
    number: number;
  };
  intervenant_id: string;
}
export interface infoEpisodeApi
  extends chosenPathEvaluationApi,
    listClassIntervenantApi {}

export type chosenPathByEpisode = {
  [episodeNumber: number]: {
    [role in roleOnEpisode]?: theme;
  };
};
export interface listIntervenant {
  number: number;
  firstAnimateurId: string | null;
  secondAnimateurId: string | null;
  expertId: string | null;
}

export interface listClassIntervenantApi {
  class: {
    episodes: listIntervenant[];
  }[];
}

export enum theme {
  theme1 = 'theme1',
  theme2 = 'theme2',
  theme3 = 'theme3',
  theme4 = 'theme4',
  NSP = 'NSP',
}

export enum roleOnEpisode {
  firstAnimator = 'firstAnimator',
  secondAnimator = 'secondAnimator',
  expert = 'expert',
}

export function roleMapping(role: roleOnEpisode) {
  switch (role) {
    case roleOnEpisode.firstAnimator:
      return 'firstAnimateurId';
    case roleOnEpisode.secondAnimator:
      return 'secondAnimateurId';
    case roleOnEpisode.expert:
      return 'expertId';
    default:
      return undefined;
  }
}

export function roleMappingInverse(role: string) {
  switch (role) {
    case 'firstAnimateurId':
      return roleOnEpisode.firstAnimator;
    case 'secondAnimateurId':
      return roleOnEpisode.secondAnimator;
    case 'expertId':
      return roleOnEpisode.expert;
    default:
      return undefined;
  }
}
