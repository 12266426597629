import React from 'react';
import {
  ConnectedHoc,
  NotConnectedHoc,
} from 'business/protectedRouteContainer';
import Home from 'business/user/pages/home';
import { Route, Router as ReactDomRouter } from 'react-router-dom';
import history from 'technical/history';
import SwitchNotFoundHandler from 'technical/router/switch';
import LoginProviderCallbackScreen from './user/pages/loginProviderCallback';
import UserProfilePage from './user/pages/profile';
import SignUp from './user/pages/signup';
import NewTrainings from './training/trainings';
import NewTrainingRegistration from './training/new-training-registration';
import Episodes from './episode/episodes';
import EpisodesRegistration from './episode/episodes-registration';
import SpeakerEvaluation from './evaluation/speaker-evaluation';
import Registration from './user/pages/registration';

const Router = () => (
  <ReactDomRouter history={history}>
    <SwitchNotFoundHandler>
      <Route path="/" exact component={ConnectedHoc(Home)} />

      <Route
        exact
        path="/login/callback"
        component={NotConnectedHoc(LoginProviderCallbackScreen)}
      />

      <Route
        path="/my-profile"
        exact
        component={ConnectedHoc(UserProfilePage)}
      />

      <Route
        path="/registration-3SY2wWahkbFWST2q"
        exact
        component={NotConnectedHoc(Registration)}
      />

      <Route path="/sign-up" exact component={ConnectedHoc(SignUp)} />

      <Route
        path="/new-trainings"
        exact
        component={ConnectedHoc(NewTrainings)}
      />

      <Route
        path="/new-training-registration"
        exact
        component={ConnectedHoc(NewTrainingRegistration)}
      />

      <Route path="/episodes" exact component={ConnectedHoc(Episodes)} />

      <Route
        path="/episodes-registration"
        exact
        component={ConnectedHoc(EpisodesRegistration)}
      />

      <Route
        path="/speaker-evaluation"
        exact
        component={ConnectedHoc(SpeakerEvaluation)}
      />
    </SwitchNotFoundHandler>
  </ReactDomRouter>
);

export default Router;
