import { TFunction } from 'i18next';

const getEvaluationConfig = (t: TFunction) => ({
  pathChosen: [
    { label: t('evaluation.pathChosen.options.NC'), value: 'NC' },
    { label: t('evaluation.pathChosen.options.NSP'), value: 'NSP' },
    { label: t('evaluation.pathChosen.options.T1'), value: 'T1' },
    { label: t('evaluation.pathChosen.options.T2'), value: 'T2' },
    { label: t('evaluation.pathChosen.options.T3a'), value: 'T3a' },
    { label: t('evaluation.pathChosen.options.T3b'), value: 'T3b' },
  ],

  enoughTime: [
    { label: t('evaluation.enoughTime.options.Y'), value: 'Y' },
    { label: t('evaluation.enoughTime.options.N'), value: 'N' },
  ],

  startOnTime: [
    { label: t('evaluation.startOnTime.options.Y'), value: 'Y' },
    { label: t('evaluation.startOnTime.options.M15M'), value: 'M15M' },
    { label: t('evaluation.startOnTime.options.P15M'), value: 'P15M' },
  ],

  sameTeacher: [
    { label: t('evaluation.sameTeacher.options.Y'), value: 'Y' },
    { label: t('evaluation.sameTeacher.options.N'), value: 'N' },
    { label: t('evaluation.sameTeacher.options.NSP'), value: 'NSP' },
    { label: t('evaluation.sameTeacher.options.NC'), value: 'NC' },
  ],

  helpFacilitateSession: [
    { label: t('evaluation.helpFacilitateSession.options.5'), value: '5' },
    { label: t('evaluation.helpFacilitateSession.options.4'), value: '4' },
    { label: t('evaluation.helpFacilitateSession.options.2'), value: '2' },
    { label: t('evaluation.helpFacilitateSession.options.1'), value: '1' },
  ],

  studentAttention: [
    { label: t('evaluation.studentAttention.options.5'), value: '5' },
    { label: t('evaluation.studentAttention.options.4'), value: '4' },
    { label: t('evaluation.studentAttention.options.2'), value: '2' },
    { label: t('evaluation.studentAttention.options.1'), value: '1' },
  ],

  studentInvolvement: [
    { label: t('evaluation.studentInvolvement.options.5'), value: '5' },
    { label: t('evaluation.studentInvolvement.options.4'), value: '4' },
    { label: t('evaluation.studentInvolvement.options.2'), value: '2' },
    { label: t('evaluation.studentInvolvement.options.1'), value: '1' },
  ],

  resolutionOrChallenge: [
    { label: t('evaluation.resolutionOrChallenge.options.5'), value: '5' },
    { label: t('evaluation.resolutionOrChallenge.options.3'), value: '3' },
    { label: t('evaluation.resolutionOrChallenge.options.1'), value: '1' },
    { label: t('evaluation.resolutionOrChallenge.options.NSP'), value: 'NSP' },
    { label: t('evaluation.resolutionOrChallenge.options.NC'), value: 'NC' },
  ],

  resolutionOrChallengeHolding: [
    {
      label: t('evaluation.resolutionOrChallengeHolding.options.5'),
      value: '5',
    },
    {
      label: t('evaluation.resolutionOrChallengeHolding.options.4'),
      value: '4',
    },
    {
      label: t('evaluation.resolutionOrChallengeHolding.options.2'),
      value: '2',
    },
    {
      label: t('evaluation.resolutionOrChallengeHolding.options.1'),
      value: '1',
    },
    {
      label: t('evaluation.resolutionOrChallengeHolding.options.NSP'),
      value: 'NSP',
    },
    {
      label: t('evaluation.resolutionOrChallengeHolding.options.NC'),
      value: 'NC',
    },
  ],
  computer: [
    { label: t('evaluation.computer.options.2'), value: '2' },
    { label: t('evaluation.computer.options.1'), value: '1' },
    { label: t('evaluation.computer.options.0'), value: '0' },
  ],

  videoProjector: [
    { label: t('evaluation.videoProjector.options.2'), value: '2' },
    { label: t('evaluation.videoProjector.options.1'), value: '1' },
  ],
  overallImpression: [
    { label: t('evaluation.overallImpression.options.5'), value: '5' },
    { label: t('evaluation.overallImpression.options.4'), value: '4' },
    { label: t('evaluation.overallImpression.options.3'), value: '3' },
    { label: t('evaluation.overallImpression.options.2'), value: '2' },
    { label: t('evaluation.overallImpression.options.1'), value: '1' },
  ],
});

export default getEvaluationConfig;
