import React, { useState } from 'react';
import {
  Table,
  Popconfirm,
  Button as AntdButton,
  Modal,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import getTime from 'technical/dateFormatter/dateFormatter';
import { Training } from '../api';
import styles from './index.module.scss';

type Props = {
  loading: boolean;
  trainings: Training[] | undefined;
  emptyText: string;
  onUnregisterConfirm?: (row: Training) => void;
};

function TrainingTable({
  trainings,
  loading,
  emptyText,
  onUnregisterConfirm,
}: Props) {
  const { t } = useTranslation();
  const [modal, setModal] = useState<{
    visible?: boolean;
    title?: string;
    content?: React.ReactNode;
  }>({});

  return (
    <>
      <Modal
        visible={modal.visible}
        title={modal.title}
        footer={null}
        closeIcon={
          <Typography.Text type="warning">
            {t('new-training.table.delete.modal.close')}
          </Typography.Text>
        }
        onCancel={() => setModal({ ...modal, visible: false })}
      >
        {modal.content}
      </Modal>
      <Table
        locale={{
          emptyText,
        }}
        rowKey="id"
        loading={loading}
        pagination={false}
        dataSource={trainings}
        columns={[
          {
            title: t('new-training.table.name'),
            dataIndex: 'name',
            render: name => name ?? t('new-training.table.defined'),
          },
          {
            title: t('new-training.table.address'),
            dataIndex: 'address',
            render: (_, row) =>
              row.address
                ? `${row.address} ${row.postalCode} ${row.city}`
                : t('new-training.table.defined'),
          },
          {
            title: t('new-training.table.date'),
            dataIndex: 'dateStart',
            render: (_, row) => {
              const formatter = Intl.DateTimeFormat('fr-FR', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              });
              return row.dateStart
                ? `${formatter.format(new Date(row.dateStart))}`
                : t('new-training.table.defined');
            },
          },
          {
            title: t('new-training.table.hour'),
            dataIndex: 'dateEnd',
            render: (_, row) => {
              if (!row.dateStart) {
                return t('new-training.table.defined');
              }

              const dateStart = new Date(row.dateStart);
              const dateEnd = new Date(row.dateEnd);

              return `${getTime(dateStart)}-${getTime(dateEnd)}`;
            },
          },
          {
            title: onUnregisterConfirm ? 'Actions' : 'Statut',
            render: (_, row) => {
              const timesAllowedForCancellation = 2592e5;
              if (onUnregisterConfirm) {
                if (
                  (row.dateStart === null && row.dateEnd === null) ||
                  new Date(row.dateEnd) >= new Date()
                ) {
                  if (
                    row.dateStart !== null &&
                    new Date(row.dateStart) <
                      new Date(Date.now() + timesAllowedForCancellation)
                  ) {
                    const regionManager = row.region.user;
                    return (
                      <AntdButton
                        type="link"
                        className={styles.buttonLinkDanger}
                        onClick={() => {
                          setModal({
                            visible: true,
                            title: t(
                              'new-training.table.delete.unregister.modal.title',
                            ),
                            content:
                              regionManager.email || regionManager.phone
                                ? t(
                                    'new-training.table.delete.unregister.modal.withRegionManagerInfos',
                                    regionManager,
                                  )
                                : t(
                                    'new-training.table.delete.unregister.modal.withoutRegionManagerInfos',
                                  ),
                          });
                        }}
                      >
                        {t('episode.my.unregister.link')}
                      </AntdButton>
                    );
                  }
                  // training is in future and start in more than 3 days
                  return (
                    <Popconfirm
                      title={t('new-training.table.delete.confirm')}
                      onConfirm={() => onUnregisterConfirm(row)}
                    >
                      <AntdButton
                        type="link"
                        className={styles.buttonLinkDanger}
                      >
                        {t('new-training.table.delete.link')}
                      </AntdButton>
                    </Popconfirm>
                  );
                }
              }
              if (row.user_trainings[0].validationDate) {
                return t('new-training.table.status.validated');
              }
              if (row.user_trainings[0].invalidationDate) {
                return t('new-training.table.status.invalidated');
              }
              return t('new-training.table.status.done');
            },
          },
        ]}
      />
    </>
  );
}

export default TrainingTable;
