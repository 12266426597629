import { useEffect } from 'react';
import { signUp } from '../services/authentication';

export default function Registration() {
  useEffect(() => {
    signUp();
  }, []);

  return null;
}
