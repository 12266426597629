/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Typography } from 'antd';
import history from 'technical/history';
import { useTranslation } from 'react-i18next';
import style from './index.module.scss';

type Props = {
  title: string;
  withBackButton?: boolean;
};

function Title({ title, withBackButton }: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <div className={style.container}>
        {withBackButton && (
          <a className={style.back} onClick={() => history.goBack()}>
            {t('common.return')}
          </a>
        )}

        <Typography.Title className={style.title}>{title}</Typography.Title>
      </div>
      <div className={style.sep} />
    </div>
  );
}

export default Title;
