import { ColumnType } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';
import { Button as AntdButton, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import styles from './index.module.scss';
import { useAppContext } from '../../provider';
import { ColumnProps, Row } from '../types/EpisodeTableTypes';

function useActionsColumn(props: ColumnProps): ColumnType<Row> {
  const { t } = useTranslation();
  const { user } = useAppContext();

  return {
    title: 'Actions',
    key: 'actions',
    render: (_, row) => {
      if (new Date(row.episode.dateEnd) >= new Date()) {
        if (
          new Date(row.episode.dateStart) < new Date(Date.now() + 12096e5) ||
          row.episode.animator_2?.id === user?.id
        ) {
          // episode is in future and start in less than 14 days
          const regionManager = row.establishment.region.user;
          return (
            <AntdButton
              type="link"
              className={styles.buttonLinkDanger}
              onClick={() => {
                props.setModal({
                  visible: true,
                  title: t('episode.my.unregister.modal.title'),
                  content:
                    regionManager.email || regionManager.phone
                      ? t(
                          'episode.my.unregister.modal.withRegionManagerInfos',
                          regionManager,
                        )
                      : t(
                          'episode.my.unregister.modal.withoutRegionManagerInfos',
                        ),
                });
              }}
            >
              {t('episode.my.unregister.link')}
            </AntdButton>
          );
        }

        // episode is in future and start in more than 14 days
        return (
          <Popconfirm
            title={t('episode.my.unregister.confirm')}
            onConfirm={() => props.onUnregisterConfirm(row)}
          >
            <AntdButton type="link" className={styles.buttonLinkDanger}>
              {t('episode.my.unregister.link')}
            </AntdButton>
          </Popconfirm>
        );
      }
      const { evaluationsData } = props;
      const existingEvaluation = evaluationsData?.speakerEvaluation.find(
        evaluation => evaluation.episode_id === row.episode.id,
      );
      if (existingEvaluation) {
        // episode is in the past and already have an evaluation
        return (
          <Link
            to={`/speaker-evaluation?episode_id=${row.episode.id}&evaluation_id=${existingEvaluation.id}&episode_number=${row.episode.number}&class_name=${row.name}&establishment_name=${row.establishment.name}`}
          >
            {t('episode.my.table.edit')}
          </Link>
        );
      }

      // episode is in the past and has no evaluation
      return (
        <Link
          to={`/speaker-evaluation?episode_id=${row.episode.id}&episode_number=${row.episode.number}&class_name=${row.name}&establishment_name=${row.establishment.name}`}
        >
          {t('episode.my.table.evaluate')}
        </Link>
      );
    },
  };
}

export default useActionsColumn;
