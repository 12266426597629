export const EpisodesSearchModes: { [id: string]: string } = {
  address: 'Par adresse, ville ou code postal',
  establishment: 'Par établissement',
  region: 'Par région',
};

export type EpisodesSearchState = {
  location?: {
    lat: number;
    lon: number;
  };
  distance: string;
  city?: string;
  postalCode?: string;
  establishment?: string;
  dateStart?: Date;
  preferredTime?: 'morning' | 'afternoon';
  searchType: string;
  regionId?: string;
  regionSearchType?: string;
};

export const EpisodeSearchStateDefault: EpisodesSearchState = {
  distance: 'null',
  searchType: 'address',
  regionSearchType: 'address'
};
