import React, { createContext, useContext } from 'react';
import logger from 'technical/logger';
import useUserData from './user/provider/user';
import { User } from './user/types';
import TableContextProvider from './episode/TabContext';

interface AppContext {
  user: User | undefined;
  isConnected: boolean;
  appBootstraped: boolean;
  requestRebootstrap: () => Promise<void>;
}

const AppContext = createContext<AppContext>({
  user: undefined,
  isConnected: false,
  appBootstraped: false,
  requestRebootstrap: () => Promise.resolve(),
});

interface Props {
  children: React.ReactNode;
}
export function AppProvider({ children }: Props) {
  const userData = useUserData();
  logger.info('AppProvider', userData);

  return (
    <AppContext.Provider
      value={{
        ...userData,
        appBootstraped: userData.isBootstraped,
      }}
    >
      <TableContextProvider>{children}</TableContextProvider>
    </AppContext.Provider>
  );
}

export const useAppContext = () => useContext(AppContext);
