import moment from 'moment';
import { Episode } from '../../business/episode/api';
import 'moment/locale/fr';

moment.locale('fr');

const formatEpisodeDate = (episode: Episode): string => {
  return (
    moment(episode.dateStart).format('dddd DD MMMM YYYY [à] HH[:]mm') || ''
  );
};

export default formatEpisodeDate;
