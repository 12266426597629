import React from 'react';

import { Button as AntdButton } from 'antd';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button/button';

import { logEvent } from 'technical/analytics';

export interface ButtonProps extends AntdButtonProps {
  eventName?: string;
  logEventData?: Object;
}

const Button = ({
  onClick: originalOnClick,
  eventName = 'unknown_event',
  logEventData,
  ...props
}: ButtonProps) => {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <AntdButton
      loading={isLoading}
      {...props}
      onClick={(...args) => {
        logEvent('button_clicked', {
          button_event: eventName,
          ...logEventData,
        });
        if (originalOnClick) {
          setIsLoading(true);
          Promise.resolve(originalOnClick(...args)).finally(() =>
            setIsLoading(false),
          );
        }
      }}
    />
  );
};

export default Button;
