import React from 'react';

type Props = {
  space?: number;
};

function Spacer({ space = 20 }: Props) {
  return (
    <div
      style={{
        width: '100%',
        marginTop: `${space}px`,
      }}
    />
  );
}

export default Spacer;
