import React from 'react';
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Radio,
  Select,
  Typography,
  Checkbox,
} from 'antd';

import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import getEvaluationConfig from './evaluationFormConfig';

type SpeakerEvaluationFormValues = {
  pathChosen?: string;
  enoughTime?: string;
  startOnTime?: string;
  sameTeacher?: string;
  helpFacilitateSession?: string;
  studentAttention?: string;
  studentInvolvement?: string;
  resolutionOrChallenge?: string;
  resolutionOrChallengeHolding?: string;
  computer?: string;
  videoProjector?: string;
  technicalIssue?: []; // Array of selected technical issue values
  overallImpression?: string;
  comments?: string;
  technicalIssueEJ?: string; // '1' or '0' depending on the checkbox status
  technicalIssueRoom?: string; // '1' or '0' depending on the checkbox status
};

type SpeakerEvaluationInitialValues = Partial<SpeakerEvaluationFormValues>;

export default function SpeakerEvaluationForm({
  initialValues,
  onFinish,
  episodeNumber,
}: {
  initialValues: SpeakerEvaluationFormValues;
  onFinish: (values: SpeakerEvaluationInitialValues) => void;
  episodeNumber: number;
}) {
  const { t } = useTranslation();
  const {
    pathChosen,
    enoughTime,
    startOnTime,
    sameTeacher,
    helpFacilitateSession,
    overallImpression,
    studentAttention,
    studentInvolvement,
    resolutionOrChallenge,
    resolutionOrChallengeHolding,
    computer,
    videoProjector,
  } = getEvaluationConfig(t);
  return (
    <Card className={styles.card}>
      <Form
        initialValues={initialValues}
        onFinish={onFinish}
        scrollToFirstError
      >
        {episodeNumber !== 3 && (
          <>
            <Typography.Title level={4}>
              {t('evaluation.pathEpisode3')}
            </Typography.Title>
            <Divider />

            <div className={styles.items}>
              <Form.Item
                label={t('evaluation.pathChosen.label.text')}
                name="pathChosen"
                rules={[
                  {
                    required: episodeNumber === 2,
                    message: t('common.mandatory'),
                  },
                ]}
                className={styles.lowMargin}
              >
                <Select dropdownMatchSelectWidth={false} options={pathChosen} />
              </Form.Item>
              <p style={{ marginTop: 12 }}>
                {t('evaluation.pathChosen.label.infoText')}{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://drive.google.com/file/d/1uV89UfDbYTC5c7F5aXFeJEXuVD9vE9To/view?usp=sharing"
                >
                  {t('evaluation.pathChosen.label.infoLink')}
                </a>
              </p>
            </div>
          </>
        )}
        <Typography.Title level={4}>
          {t('evaluation.timeManagment')}
        </Typography.Title>
        <Divider />
        <div className={styles.items}>
          <Form.Item
            className={styles.flex}
            label={t('evaluation.enoughTime.label.text')}
            colon={false}
            name="enoughTime"
            rules={[{ required: true, message: t('common.mandatory') }]}
          >
            <Radio.Group
              options={enoughTime}
              className={styles.radioGroupWithValues}
            />
          </Form.Item>
          <Form.Item
            className={styles.flex}
            label={t('evaluation.startOnTime.label.text')}
            colon={false}
            name="startOnTime"
            rules={[{ required: true, message: t('common.mandatory') }]}
          >
            <Radio.Group
              options={startOnTime}
              className={styles.radioGroupWithValues}
            />
          </Form.Item>
        </div>

        <Typography.Title level={4}>
          {t('evaluation.teacherInvolvement')}
        </Typography.Title>
        <Divider />
        <div className={styles.items}>
          <Form.Item
            className={styles.flex}
            label={t('evaluation.sameTeacher.label.text')}
            name="sameTeacher"
            rules={[{ required: true, message: t('common.mandatory') }]}
          >
            <Radio.Group
              options={sameTeacher}
              className={styles.radioGroupWithValues}
            />
          </Form.Item>
          <Form.Item
            className={styles.flex}
            label={t('evaluation.helpFacilitateSession.label.text')}
            name="helpFacilitateSession"
            rules={[{ required: true, message: t('common.mandatory') }]}
          >
            <Radio.Group
              options={helpFacilitateSession}
              className={styles.radioGroupWithValues}
            />
          </Form.Item>
        </div>

        <Typography.Title level={4}>
          {t('evaluation.studentBehaviour')}
        </Typography.Title>
        <Divider />
        <div className={styles.items}>
          <Form.Item
            className={styles.flex}
            label={t('evaluation.studentAttention.label.text')}
            name="studentAttention"
            rules={[{ required: true, message: t('common.mandatory') }]}
          >
            <Radio.Group
              options={studentAttention}
              className={styles.radioGroupWithValues}
            />
          </Form.Item>
          <Form.Item
            className={styles.flex}
            label={t('evaluation.studentInvolvement.label.text')}
            name="studentInvolvement"
            rules={[{ required: true, message: t('common.mandatory') }]}
          >
            <Radio.Group
              options={studentInvolvement}
              className={styles.radioGroupWithValues}
            />
          </Form.Item>

          <Form.Item
            className={styles.flex}
            label={
              <span>
                {t('evaluation.resolutionOrChallenge.label.text')}{' '}
                <span className={styles.labelInfo}>
                  {t('evaluation.resolutionOrChallenge.label.info')}
                </span>
              </span>
            }
            name="resolutionOrChallenge"
            rules={[{ required: true, message: t('common.mandatory') }]}
          >
            <Radio.Group
              className={styles.radioGroupWithValues}
              options={resolutionOrChallenge}
            />
          </Form.Item>

          <Form.Item
            className={styles.flex}
            label={
              <span>
                {t('evaluation.resolutionOrChallengeHolding.label.text')}{' '}
                <span className={styles.labelInfo}>
                  {t('evaluation.resolutionOrChallengeHolding.label.info')}
                </span>
              </span>
            }
            name="resolutionOrChallengeHolding"
            rules={[{ required: true, message: t('common.mandatory') }]}
          >
            <Radio.Group
              className={styles.radioGroupWithValues}
              options={resolutionOrChallengeHolding}
            />
          </Form.Item>
        </div>

        <Typography.Title level={4}>
          {t('evaluation.equipment')}
        </Typography.Title>
        <Divider />
        <div className={styles.items}>
          <Form.Item
            className={styles.flex}
            label={t('evaluation.computer.label.text')}
            colon={false}
            name="computer"
            rules={[{ required: true, message: t('common.mandatory') }]}
          >
            <Radio.Group
              options={computer}
              className={styles.radioGroupWithValues}
            />
          </Form.Item>
          <Form.Item
            className={styles.flex}
            label={t('evaluation.videoProjector.label.text')}
            colon={false}
            name="videoProjector"
            rules={[{ required: true, message: t('common.mandatory') }]}
          >
            <Radio.Group
              options={videoProjector}
              className={styles.radioGroupWithValues}
            />
          </Form.Item>
          <Form.Item
            className={styles.flex}
            label={
              <p className={styles.labelHeight}>
                {t('evaluation.technicalIssues.label.text')}{' '}
                <span className={styles.labelInfo}>
                  {t('evaluation.technicalIssues.label.info')}
                </span>
                <br />
                <p> {t('evaluation.technicalIssues.label.only')} </p>
              </p>
            }
            colon={false}
          >
            <Form.Item
              className={`${styles.flex} ${styles.noSpacing}`}
              name="technicalIssue"
            >
              <Checkbox.Group className={styles.checkboxGroup}>
                <Checkbox className={styles.checkbox} value="technicalIssueEJ">
                  {t('evaluation.technicalIssues.options.technicalIssueEJ')}
                </Checkbox>
                <Checkbox
                  value="technicalIssueRoom"
                  className={styles.checkbox}
                >
                  {t('evaluation.technicalIssues.options.technicalIssueRoom')}
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Form.Item>
        </div>

        <Typography.Title level={4}>{t('evaluation.overall')}</Typography.Title>
        <Divider />
        <div className={styles.items}>
          <Form.Item
            className={styles.flex}
            label={t('evaluation.overallImpression.label.text')}
            name="overallImpression"
            rules={[{ required: true, message: t('common.mandatory') }]}
          >
            <Radio.Group
              options={overallImpression}
              className={styles.radioGroupWithValues}
            />
          </Form.Item>

          <Form.Item
            label={t('evaluation.comments')}
            labelAlign="left"
            name="comments"
            className={styles.ctnTextArea}
            rules={[{ required: true, message: t('common.mandatory') }]}
          >
            <Input.TextArea rows={7} className={styles.textArea} />
          </Form.Item>
        </div>

        <Button type="primary" htmlType="submit">
          {t('evaluation.submit')}
        </Button>
      </Form>
    </Card>
  );
}
