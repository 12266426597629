import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  message,
  Typography,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useAppContext } from 'business/provider';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logger from 'technical/logger';
import { PHONE_NUMBER } from 'technical/validation/rules';
import config from 'config';
import { GET_REGION, RegionData } from './api/region';
import { GET_SITE, SiteData } from './api/site';
import UPDATE_USER from './api/user';
import { renewToken } from '../../../business/user/services/authentication';

const userStatus = [
  'active-in-company',
  'egee',
  'independent',
  'seconded-employee',
  'ej-employee',
  'civic-service',
];

const userToSiteStatus: { [key: string]: string | undefined } = {
  'active-in-company': 'partner-company',
  egee: 'egee',
  independent: 'independent',
  'seconded-employee': 'partner-company',
  'ej-employee': 'ej',
  'civic-service': 'civic-service',
};

type Props = {
  submitText: string;
  withTerms?: boolean;
  disableRegion?: boolean;
};

function UserForm({ submitText, withTerms, disableRegion }: Props) {
  const { user } = useAppContext();
  const { t } = useTranslation();
  const [status, setStatus] = useState<string>();
  const [regionId, setRegionId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { data: regionData } = useQuery<RegionData>(GET_REGION);
  const [getSite, { data: siteData }] = useLazyQuery<SiteData>(GET_SITE);
  const [updateUser] = useMutation(UPDATE_USER);
  const form = useRef<FormInstance>(null);
  const { requestRebootstrap } = useAppContext();

  useEffect(() => {
    if (user && user.origin && status !== user.origin) {
      setStatus(user.origin);
    }
    if (user && user.regionId && regionId !== user.regionId) {
      setRegionId(user.regionId);
    }
    // [TO FIX] Julien problem vscode, can't see the eslint(react-hooks/exhaustive-deps) so default eslint disable for now
    // eslint-disable-next-line
  }, []); // Called once, only for initialization of the state

  useEffect(() => {
    if (regionId) {
      getSite({
        variables: {
          origin: status && userToSiteStatus[status],
          regionId,
        },
      });
    }
  }, [regionId, status, getSite]);

  const onFinish = (values: any) => {
    setLoading(true);

    if (user) {
      updateUser({
        variables: {
          id: user.id,
          firstname: values.firstname,
          lastname: values.lastname,
          civility: values.civility,
          phone: values.phone,
          regionId: values.region,
          origin: values.status,
          siteId: values.site,
          newPackNotification: values.newPackNotification,
          inscriptionNotification: values.inscriptionNotification
        },
      })
        .then(renewToken)
        .then(requestRebootstrap)
        .then(() => {
          message.success(t('user.signup.form.success'));
          setLoading(false);
        })
        .catch(e => logger.error(e));
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Form
      labelCol={{
        xs: { span: 24 },
        sm: { span: 8 },
      }}
      wrapperCol={{
        xs: { span: 24 },
        sm: { span: 16 },
      }}
      name="signup"
      initialValues={{
        firstname: user.firstName,
        lastname: user.lastName,
        email: user.email,
        phone: user.phone,
        civility: user.civility,
        region: user.regionId,
        oldRegion: user.oldRegionId,
        changeRegionDate: user.changeRegionDate,
        status: user.origin,
        newPackNotification: user.newPackNotification,
        inscriptionNotification: user.inscriptionNotification,
        site: user.siteId,
      }}
      onFinish={onFinish}
      ref={form}
      onValuesChange={values => {
        if (values.status) {
          setStatus(values.status);
        }

        if (values.region) {
          setRegionId(values.region);
        }

        if (values.status || values.region) {
          form.current?.setFieldsValue({ site: null });
        }
      }}
    >
      {values => (
        <>
          <Form.Item
            label={t('user.signup.form.firstname')}
            name="firstname"
            rules={[{ required: true, message: t('common.mandatory') }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('user.signup.form.lastname')}
            name="lastname"
            rules={[{ required: true, message: t('common.mandatory') }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('user.signup.form.civility')}
            name="civility"
            rules={[{ required: true, message: t('common.mandatory') }]}
          >
            <Select>
              <Select.Option value="mr">
                {t('user.signup.form.mr')}
              </Select.Option>
              <Select.Option value="mrs">
                {t('user.signup.form.mrs')}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={t('user.signup.form.email')}
            name="email"
            rules={[
              { type: 'email', message: 'Email' },
              { required: true, message: t('common.mandatory') },
            ]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label={t('user.signup.form.phone.label')}
            name="phone"
            rules={[
              { required: true, message: ' ' }, // a empty message so that empty value is handled by the vlidator
              {
                validator: (_, value) =>
                  PHONE_NUMBER.format.pattern.test(value)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(t(`errors.${PHONE_NUMBER.format.message}`)),
                      ),
              },
            ]}
          >
            <Input placeholder={t('user.signup.form.phone.placeholder')} />
          </Form.Item>

          {disableRegion && user.changeRegionDate && (
            <div
              style={{
                textAlign: 'end',
                color: '#c2c2c2',
              }}
            >
              Date de changement :{' '}
              {new Date(user.changeRegionDate).toLocaleDateString()}
            </div>
          )}

          {disableRegion && user.oldRegionId && (
            <Form.Item
              label={t('user.signup.form.oldRegion')}
              name="oldRegion"
              rules={[{ required: true, message: t('common.mandatory') }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                disabled={disableRegion}
              >
                {regionData?.region.map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            label={t('user.signup.form.region')}
            name="region"
            rules={[{ required: true, message: t('common.mandatory') }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              disabled={disableRegion}
            >
              {regionData?.region.map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {disableRegion && (
            <Form.Item
              label={t('user.signup.form.regionsAnimation')}
              name="regionsAnimation"
            >
              <Select
                mode="multiple"
                defaultValue={user.regionsAnimation?.map(r => r.id)}
                disabled
              >
                {user.regionsAnimation?.map(({ id, name }) => (
                  <Select.Option key={id} value={id} disabled>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            label={t('user.signup.form.status')}
            name="status"
            rules={[{ required: true, message: t('common.mandatory') }]}
          >
            <Select dropdownMatchSelectWidth={false}>
              {userStatus.map(value => (
                <Select.Option key={value} value={value}>
                  {t(`user.signup.status.${value}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {status && regionId && siteData?.site && siteData.site.length > 0 && (
            <Form.Item
              label={t('user.signup.form.site')}
              name="site"
              rules={[{ required: true, message: t('common.mandatory') }]}
            >
              <Select dropdownMatchSelectWidth={false}>
                {siteData?.site.map(s => (
                  <Select.Option key={s.id} value={s.id}>
                    {s.organisationName} - {s.address}, {s.postalCode} {s.city}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {withTerms && (
            <Form.Item
              name="acceptTerms"
              valuePropName="checked"
              wrapperCol={{ offset: 8, span: 16 }}
            >
              <Checkbox>
                {t('user.signup.form.acceptTerms.1')}{' '}
                <a
                  href="https://energiejeunes.fr/wp-content/uploads/2022/09/Charte-du-volontaire-EJ-2022-2023.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('user.signup.form.acceptTerms.2')}
                </a>{' '}
                {t('user.signup.form.acceptTerms.3')}{' '}
                <a
                  href="https://drive.google.com/drive/folders/1_QKx-YQ19lLY8m2g2vdoYqo4Va7HjyXi?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('user.signup.form.acceptTerms.4')}
                </a>
              </Checkbox>
            </Form.Item>
          )}

          <Form.Item
            name="newPackNotification"
            valuePropName="checked"
            wrapperCol={{ offset: 8, span: 16 }}
          >
            <Checkbox>{t('user.signup.form.newPackNotification')}</Checkbox>
          </Form.Item>
          <Form.Item
            name="inscriptionNotification"
            valuePropName="checked"
            wrapperCol={{ offset: 8, span: 16 }}
          >
            <Checkbox>{t('user.signup.form.inscriptionNotification')}</Checkbox>
          </Form.Item>
          <Typography.Text
            style={{
              display: 'block',
              marginBottom: '24px',
              textAlign: 'center',
              textTransform: 'capitalize',
            }}
          >
            <a
              href={config.links.volunteerConvention}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('user.signup.form.acceptTerms.2')}
            </a>
          </Typography.Text>
          <Typography.Text
            style={{
              display: 'block',
              marginBottom: '24px',
              textAlign: 'center',
              textTransform: 'capitalize',
            }}
          >
            <a
              href={config.links.rgpd}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('user.signup.form.acceptTerms.4')}
            </a>
          </Typography.Text>
          <Form.Item
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 8 },
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              disabled={withTerms && !values.acceptTerms}
              loading={loading}
            >
              {submitText}
            </Button>
          </Form.Item>
        </>
      )}
    </Form>
  );
}

export default UserForm;
