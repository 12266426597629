import { Typography } from 'antd';
import { useAppContext } from 'business/provider';
import colors from 'config/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import UserForm from 'ui/form/userForm';
import Flex from '../../../ui/flex';
import ConnectedPage from '../../layout/connectedPage';
import styles from './index.module.scss';

function SignUp() {
  const { t } = useTranslation();
  const { user } = useAppContext();

  if (user?.regionId) {
    return <Redirect to="/" />;
  }

  return (
    <ConnectedPage>
      <Flex column alignItems="center">
        <Typography.Title level={3} style={{ color: colors.primaryColor }}>
          {t('user.signup.title')}
        </Typography.Title>
        <div className={styles.formWrapper}>
          <UserForm withTerms submitText={t('user.signup.form.submit')} />
        </div>
      </Flex>
    </ConnectedPage>
  );
}

export default SignUp;
