import gql from 'graphql-tag';

export const GET_REGION = gql`
  query GetRegions {
    region {
      id
      name
    }
  }
`;

type Region = {
  id: string;
  name: string;
};

export type RegionData = {
  region: Region[];
};
