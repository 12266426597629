import React from 'react';
import MenuLink from 'ui/menu/link';
import appLogo from 'config/AppLogo.svg';
import styles from './index.module.scss';

export default function AppLogo() {
  return (
    <MenuLink path="/">
      <img className={styles.appLogo} src={appLogo} alt="App logo" />
    </MenuLink>
  );
}
